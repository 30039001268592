import React, { useCallback, useState } from "react";
import "./CardDisplay.css";

// Same as in <top level>/models/card.js
const Suits = ["S", "H", "C", "D"];
const Ranks = ["2", "3", "4", "5", "6", "7", "8", "9", "0", "J", "Q", "K", "A"];

// Read all card images
var CardImagesList = {};
Suits.forEach((s) => {
  Ranks.forEach((r) => {
    const cardKey = `${r}${s}`;
    CardImagesList[cardKey] = require("../../assets/cards/" + cardKey + ".svg").default;
  });
});
const CardBack = {
  red: require("../../assets/cards/back-red.svg").default,
  black: require("../../assets/cards/back-black.svg").default,
};

const TrioCard = (props) => {
  const { card, onClick, small } = props;
  var selectedCard = card in CardImagesList ? CardImagesList[card] : CardBack["red"];
  if (props.flipped) {
    selectedCard = CardBack["red"];
  }

  return (
    <img
      src={selectedCard}
      alt=""
      className={small ? "triocard-small" : "triocard"}
      draggable={false}
      onClick={onClick}
      style={props.style}
    />
  );
};

const ClickableCard = (props) => {
  const { card, onCardClicked, selected, displaceOffset, overlapOffset } = props;

  //outline: "2px solid red"
  const styleWhenSelected = selected && { boxShadow: "0 5px 10px 0 red", marginTop: displaceOffset };

  return (
    <TrioCard
      card={card}
      onClick={onCardClicked}
      style={{ ...styleWhenSelected, marginLeft: overlapOffset }}
      small={props.small}
    />
  );
};

const HandDisplay = (props) => {
  const { hand, onCardPlay, twoRows } = props;
  const [selectedCard, setSelectedCard] = useState(null);

  const onCardClicked = useCallback(
    (card) => {
      if (selectedCard === card) {
        // If the same card is selected, play it
        if (onCardPlay) onCardPlay(card);
      } else {
        // Select the card
        setSelectedCard(card);
      }
    },
    [onCardPlay, selectedCard]
  );

  let rows = new Array(twoRows ? 2 : 1);

  rows[0] = hand.slice(0, twoRows ? 7 : 13);
  if (twoRows) {
    rows[1] = hand.slice(7, 13);
  }

  return (
    <div className="d-flex flex-column align-items-center">
      {rows.map((cardsInRow, index) => (
        <div className="d-flex flex-row justify-content-center h-100 mb-1" key={`row${index}`}>
          {cardsInRow.map((c, cIndex) => (
            <ClickableCard
              card={c}
              key={`c${cIndex}`}
              onCardClicked={() => onCardClicked(c)}
              selected={selectedCard === c}
              displaceOffset="-1em"
              overlapOffset={cIndex > 0 ? "-1em" : ""}
              small={props.small}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export { HandDisplay, ClickableCard };
