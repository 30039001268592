import React, { useState, useCallback } from "react";
import { logError } from "../../helpers/logger";
import { TrioKingPlayTypes } from "../../shared/constants";
import TrioGameService from "../../services/triogame.service";

const TrioPlaySelector = (props) => {
//   const { game, playerIndex } = props;
  let remainingPlays = {};
  Object.values(TrioKingPlayTypes)
    .sort()
    .forEach((pt) => {
      if (pt !== TrioKingPlayTypes.SANZATU) remainingPlays[pt] = 2;
    });
  const [selectedPlay, setSelectedPlay] = useState(remainingPlays && Object.keys(remainingPlays)[0]);

  const onSelectPlay = useCallback(() => {
    // Find the key for selected play
    const keyForPlay = Object.keys(TrioKingPlayTypes).find((key) => TrioKingPlayTypes[key] === selectedPlay);
    TrioGameService.selectPlay({ type: keyForPlay }).catch((e) => {
      /* Ignore error */
      if (e.response.data && e.response.data.error) {
        logError(e.response.data.error);
      } else {
        logError(`An error occured starting the play.`);
      }
    });
  }, [selectedPlay]);

  return (
    <div className="d-flex flex-column w-100 justify-content-center align-items-center">
      <div className="h3 text-white mb-4">Oyun Seçin</div>
      <div className="d-flex h-100 w-75 justify-content-center align-items-center">
        <select
          className="form-control form-control-sm m-0"
          onChange={(e) => setSelectedPlay(e.target.value)}
          value={selectedPlay}
        >
          {Object.keys(remainingPlays)
            .sort()
            .map((k, index) => (
              <option key={`play-${index}`} value={k}>
                {k.includes("Koz") ? `${k}` : `${k} (Kalan: ${2 - remainingPlays[k]})`}
              </option>
            ))}
        </select>
        <div className="col-sm-4 p-0">
          <button className="btn btn-sm btn-primary btn-block" onClick={onSelectPlay}>
            Seç
          </button>
        </div>
      </div>
    </div>
  );
};
export { TrioPlaySelector };
