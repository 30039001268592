import React, { useCallback, useEffect, useRef, useState } from "react";

import GameService from "../services/game.service";
import AuthService from "../services/auth.service";
import { AdminControls } from "./AdminControls";
import { bindToEvents } from "../helpers/socket";

const BoardAdmin = () => {
  const [player, setPlayer] = useState(null);
  const socket = useRef(null);
  const [game, setGame] = useState({ state: "idle" });
  const isPlayerAdmin = player && player.roles && player.roles.includes("ROLE_ADMIN");

  const refreshComponent = useCallback(() => {
    GameService.getGameState()
      .then((response) => {
        setGame(response.data.game);
      })
      .catch((e) => {
        // Dont do anything and show login page
        // AuthService.logout();
        return;
      });
  }, []);

  // This event is sent every time game is updated
  const onGameChangeEvent = useCallback((eventData) => {
    // Refresh component when game changes
    refreshComponent();
  }, [refreshComponent]);

  useEffect(() => {
    setPlayer(AuthService.getCurrentPlayer());
    refreshComponent();
    // Bind event Socket.IO handler
    socket.current = bindToEvents({ onGameChanged: onGameChangeEvent }, `${window.location.host}`);

    // Clear intervalId on return
    return () => {
      // Remove listeners from socket. IS this really needed??
      socket.current && socket.current.removeAllListeners();
    };
  }, [refreshComponent]);
  
  return (
    <div className="d-flex-fluid flex-column justify-contents-center w-100">
      {isPlayerAdmin && (
        <div className="d-flex adminArea">
          <AdminControls game={game} />
        </div>
      )}
      {!isPlayerAdmin && <a href="/login"> Lütfen yönetici olarak giriş yapın </a>}
    </div>
  );
};

export default BoardAdmin;
