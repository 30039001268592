import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "/api";
const PLAYER_API_URL = `${API_URL}/player`;

class PlayerService {
  getPublicContent() {
    return axios.get(`${API_URL}/test/all`);
  }

  getPlayerBoard() {
    return axios.get(`${API_URL}/test/player`, { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(`${API_URL}/test/admin`, { headers: authHeader() });
  }

  getRegisteredPlayers() {
    return axios.get(`${PLAYER_API_URL}`, { headers: authHeader() });
  }
}

export default new PlayerService();
