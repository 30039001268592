import React, { useCallback, useEffect, useRef, useState } from "react";
import { PlayerArea, MyOwnArea } from "./PlayerAreas";
import { TableTop } from "./TableTop";
import GameService from "../services/game.service";
import { ScoreCardEditor } from "./ScoreCardEditor";
import { PlayedRoundsDisplay } from "./PlayedRoundsDisplay";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { NegativeHandsStats } from "./NegativeHandsStats";
import { PlaySelector } from "./PlaySelector";
import capitalize from "../helpers/capitalize";
import { GiHearts, GiSpades, GiClubs, GiDiamonds } from "react-icons/gi";
import { FaTable, FaLayerGroup, FaVolumeUp, FaVolumeMute, FaVideo, FaVideoSlash } from "react-icons/fa";
import VideoChatController from "./VideoChatController";
import { PlaysInfo, getScoreCard, getTotalScore } from "./PlaysInfo";

const GameInfo = (props) => {
  const { game, onOpenScoreCard, onOpenPlayedRounds } = props;
  if (!game || !game.state) {
    return <></>;
  }

  var play = game.state === "playon" ? game.currentPlay : "Oyun Seçilmedi";
  const remainingPlays = 20 - game.currentPlayNo || 0;

  // Give some colors to "koz" types
  if (play && play.includes("Koz")) {
    var kozType = play.replace("Koz", "").trim();
    var textColor = "#f00";

    if (kozType === "Maça" || kozType === "Sinek") {
      textColor = "#000";
    }

    var kozIcon;
    switch (kozType) {
      case "Maça":
        kozIcon = <GiSpades />;
        break;
      case "Karo":
        kozIcon = <GiDiamonds />;
        break;
      case "Kupa":
        kozIcon = <GiHearts />;
        break;
      case "Sinek":
        kozIcon = <GiClubs />;
        break;
      default:
        break;
    }

    play = (
      <div className="d-flex flex-row align-items-center text-light">
        <div>Koz</div>
        <div className="d-flex ml-2" style={{ color: textColor, fontSize: "1.3em" }}>{kozIcon}</div>
      </div>
    );
  }

  return (
    <div className="d-flex flex-column p-2" style={{ backgroundColor: "#555", borderRadius: "1em", height: "8em" }}>
      <div className="d-flex flex-row justify-content-center align-items-center text-warning h2">{play}</div>
      <div className="d-flex flex-row justify-content-between">
        <div className="d-flex flex-column h-100 w-75">
          <div className="d-flex flex-row justify-content-between text-light small mr-2">
            <div className="text-light">Kalan Oyun:</div>
            <div>{remainingPlays}</div>
          </div>
          <div className="d-flex flex-row justify-content-between text-light small mr-2">
            <div className="text-light">El:</div>
            <div>{game.roundNo >= 0 ? `${game.roundNo + 1} / 13` : "-"}</div>
          </div>
        </div>
        <div className="d-flex flex-row align-self-center">
          <button
            type="button"
            className="d-flex align-items-center justify-content-center btn btn-secondary mr-1"
            data-toggle="tooltip"
            title="Çetele"
            onClick={onOpenScoreCard}
          >
            <FaTable />
          </button>
          <button
            type="button"
            className="d-flex align-items-center justify-content-center btn btn-secondary"
            data-toggle="tooltip"
            title="Alınan Eller"
            onClick={onOpenPlayedRounds}
          >
            <FaLayerGroup />
          </button>
        </div>
      </div>
    </div>
  );
};
const GameTable = (props) => {
  const { player, game } = props;
  const [scoreCardOpen, setScoreCardOpen] = useState(false);
  const [playedRoundsOpen, setPlayedRoundsOpen] = useState(false);
  const isPlayerAdmin = player && player.roles && player.roles.includes("ROLE_ADMIN");
  const isGameStarted = game.state && (game.state === "gameon" || game.state === "playon");
  const myOwnVideoRef = useRef();
  const videoChatController = useRef(null);
  const [ownVideoStream, setOwnVideoStream] = useState(null);
  const [volumeOn, setVolumeOn] = useState(true);
  const [videoOn, setVideoOn] = useState(true);

  useEffect(() => {
    if (!videoChatController.current) {
      videoChatController.current = new VideoChatController({ player: player, setOwnVideoStream: setOwnVideoStream });
    }
    // Set my own video stream
    if (ownVideoStream) {
      if (myOwnVideoRef.current) {
        myOwnVideoRef.current.srcObject = ownVideoStream;
      }
    }
  }, [player, ownVideoStream]);

  if (!game) {
    return null;
  }

  // Find myself in the players list
  const myPlayerIndex = player && game.playerList && game.playerList.findIndex((p) => p.name === player.name);
  const myself = myPlayerIndex >= 0 && game.playerList[myPlayerIndex];
  const myHand = myPlayerIndex >= 0 && game.playerHands[myPlayerIndex];

  const isCouplesGame = game.gameType === "esliking";
  const didPlayerStartPlay =
    game.playerList && game.gameStartTurn >= 0 && game.playerList[(game.gameStartTurn + 3) % 4].id === player.id;
  const partnerIndex = isCouplesGame && (myPlayerIndex + 2) % game.playerList.length;
  const didPartnerStartPlay = isCouplesGame && partnerIndex === (game.gameStartTurn + 3) % 4;

  // Find other players
  const playerLeft = game.playerList && game.playerList[(myPlayerIndex + 1) % game.playerList.length];
  const playerTop = game.playerList && game.playerList[(myPlayerIndex + 2) % game.playerList.length];
  const playerRight = game.playerList && game.playerList[(myPlayerIndex + 3) % game.playerList.length];

  const isMyTurn = myPlayerIndex >= 0 && myPlayerIndex === game.playerTurn;

  const showPlayerLeftHand =
    isCouplesGame &&
    game.playerList[(game.gameStartTurn + 3) % 4].id === playerRight.id && // if player right started the game
    game.state === "playon" &&
    (game.roundNo > 0 || game.currentRound.length > 0);

  const showPartnersHand =
    isCouplesGame &&
    (didPlayerStartPlay || didPartnerStartPlay) &&
    game.state === "playon" &&
    (game.roundNo > 0 || game.currentRound.length > 0);

  const showPlayerRightHand =
    isCouplesGame &&
    game.playerList[(game.gameStartTurn + 3) % 4].id === playerLeft.id && // if player left started the game
    game.state === "playon" &&
    (game.roundNo > 0 || game.currentRound.length > 0);
  const scoreCard = getScoreCard(game, player);
  // Calculate player score
  const totalScore = getTotalScore(scoreCard, isCouplesGame);

  const otherTeamScoreCard = isCouplesGame && getScoreCard(game, playerLeft);
  const otherTeamScore = isCouplesGame && getTotalScore(otherTeamScoreCard, isCouplesGame);

  const onCardPlayed = (card) => {
    if (game.subState !== "waitplayers" || game.playerTurn !== myPlayerIndex) {
      // Cant play
      return;
    }
    GameService.playCard(player.id, card)
      .then((res) => {
        // OK
      })
      .catch((e) => {
        if (e.response.data && e.response.data.error) {
          console.error(e.response.data.error);
        } else {
          console.error(`An error occured starting the play.`);
        }
      });
  };

  const volumeToggle = useCallback(() => {
    if (!ownVideoStream) return;

    for (let index in ownVideoStream.getAudioTracks()) {
      ownVideoStream.getAudioTracks()[index].enabled = !volumeOn;
    }

    setVolumeOn(!volumeOn);
  }, [volumeOn, ownVideoStream]);

  const videoToggle = useCallback(() => {
    if (!ownVideoStream) return;

    for (let index in ownVideoStream.getAudioTracks()) {
      ownVideoStream.getVideoTracks()[index].enabled = !videoOn;
    }

    setVideoOn(!videoOn);
  }, [videoOn, ownVideoStream]);

  return (
    <div className="d-flex w-100 h-100">
      {game && (
        <div>
          <Modal
            open={scoreCardOpen}
            onClose={() => setScoreCardOpen(false)}
            center
            animationDuration="300"
            focusTrapped={false}
          >
            <div className="h4 my-2">Çetele</div>
            <div>
              <ScoreCardEditor game={game} />
            </div>
          </Modal>
          <Modal
            open={playedRoundsOpen}
            onClose={() => setPlayedRoundsOpen(false)}
            center
            animationDuration="300"
            focusTrapped={false}
          >
            <div className="h4 my-2" style={{ minWidth: "8em" }}>
              Alınan Eller
            </div>
            <div>
              <PlayedRoundsDisplay game={game} player={myself} />
            </div>
          </Modal>
        </div>
      )}
      <div className="d-flex flex-column h-100 w-100">
        <div className="d-flex flex-row justify-content-between" style={{ height: "28%" }}>
          <div className="d-flex flex-column ml-1 align-self-start h-100" style={{ width: "20%" }}>
            <div className="align-self-start border border-white rounded w-100">
              <NegativeHandsStats game={game} />
            </div>
            {isCouplesGame && (
              <div className="border border-light rounded w-100 mt-auto d-flex flex-column p-2">
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <div className="h5 text-white m-0">Karşı Takım</div>
                  <div className="d-flex flex-row align-items-center">
                    <PlaysInfo scoreCard={otherTeamScoreCard} couplesGame={true} imageHeight="0.75em" />
                  </div>
                </div>
                <div className={`align-self-center scoreArea ${totalScore < 0 ? "text-danger" : "text-success"}`}>
                  {otherTeamScore}
                </div>
              </div>
            )}
          </div>
          <div className="d-flex flex-column h-100" style={{ width: "50%" }}>
            <PlayerArea
              player={playerTop}
              isPlayerAdmin={isPlayerAdmin}
              game={game}
              showHand={showPartnersHand}
              brief={isCouplesGame}
              playerStyle="topPlayer"
            />
          </div>
          <div className="mr-1 align-self-start" style={{ width: "20%" }}>
            {game && (
              <GameInfo
                game={game}
                onOpenScoreCard={() => setScoreCardOpen(true)}
                onOpenPlayedRounds={() => setPlayedRoundsOpen(true)}
              />
            )}
          </div>
        </div>
        <div className="d-flex flex-row w-100" style={{ height: "40%" }}>
          <div className="d-flex flex-column align-self-center mr-2 align-items-start" style={{ width: "35%" }}>
            <PlayerArea
              player={playerLeft}
              isPlayerAdmin={isPlayerAdmin}
              game={game}
              showHand={showPlayerLeftHand}
              brief={isCouplesGame}
              playerStyle="sidePlayer"
              reverseInfoBoxOrder={true}
            />
          </div>
          <div className="d-flex flex-column justify-content-center p-1 align-items-center" style={{ width: "30%" }}>
            {game && game.state !== "playon" ? (
              <div className="d-flex w-100 h-100 align-items-center justify-content-center">
                {isMyTurn ? (
                  <PlaySelector game={game} playerIndex={myPlayerIndex} />
                ) : (
                  <div className="h2 text-light text-center">
                    {isCouplesGame && (
                      <>
                        {capitalize(game.playerList[(game.playerTurn + 2) % 4].name)} öneriyor
                        <br />
                      </>
                    )}
                    <strong>{capitalize(game.playerList[game.playerTurn].name)}</strong> oyun seçiyor...
                  </div>
                )}
              </div>
            ) : (
              <TableTop
                players={game.playerList}
                whosTurn={isGameStarted && game.subState !== "endofplay" ? game.playerTurn : -1}
                playerIndex={myPlayerIndex}
                currentRound={game.currentRound}
              />
            )}
          </div>
          <div className="d-flex flex-column align-self-center ml-2 align-items-end" style={{ width: "35%" }}>
            <PlayerArea
              player={playerRight}
              isPlayerAdmin={isPlayerAdmin}
              game={game}
              showHand={showPlayerRightHand}
              playerStyle="sidePlayer"
              brief={isCouplesGame}
            />
          </div>
        </div>
        <div className="d-flex mt-2" style={{ height: "28%" }}>
          <MyOwnArea
            myself={myself}
            myHand={myHand}
            onCardPlayed={onCardPlayed}
            isPlayerAdmin={isPlayerAdmin}
            game={game}
          />
        </div>
      </div>
      <div style={{ position: "absolute", left: 10, bottom: 10, height: "150px" }} className="d-flex">
        <video
          height="150px"
          playsInline
          muted
          autoPlay
          poster="videoposter.png"
          ref={myOwnVideoRef}
          style={{ borderRadius: "1em", border: "2px solid cyan" }}
        ></video>
        <div className="d-flex flex-column justify-content-start">
          <div
            className={`btn btn-sm mx-1 mb-1 ${volumeOn ? "btn-success" : "btn-danger"}`}
            onClick={volumeToggle}
            data-toggle="tooltip"
            title={volumeOn ? "Sesi Kapat" : "Sesi Aç"}
          >
            {volumeOn ? <FaVolumeUp /> : <FaVolumeMute />}
          </div>
          <div
            className={`btn btn-sm m-1 ${videoOn ? "btn-success" : "btn-danger"}`}
            onClick={videoToggle}
            data-toggle="tooltip"
            title={videoOn ? "Görüntüyü Kapat" : "Görüntüyü Aç"}
          >
            {videoOn ? <FaVideo /> : <FaVideoSlash />}
          </div>
        </div>
      </div>
    </div>
  );
};

export { GameTable };
