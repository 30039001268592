import React from "react";
import GameService from "../services/game.service";
import capitalize from "../helpers/capitalize";

const ScoreCardEditor = (props) => {
  const { game, setErrorMessage, setInfoMessage, isPlayerAdmin } = props;

  const playerNameHeaders =
    game.playerList && game.playerList.map((p, index) => <th key={`player-${index}`}>{capitalize(p.name)}</th>);
  var playResults = [];

  if (game.scoreCards && game.scoreCards.length > 0) {
    for (let p = 0; p < game.scoreCards[0].playResults.length; p++) {
      playResults.push([
        game.scoreCards[0].playResults[p].playType,
        game.scoreCards[0].playResults[p].playScore,
        game.scoreCards[1].playResults[p].playScore,
        game.scoreCards[2].playResults[p].playScore,
        game.scoreCards[3].playResults[p].playScore,
      ]);
    }
  }

  const rows = playResults.map((r, ri) => {
    return (
      <tr key={`r${ri}`}>
        {r.map((e, ei) => (
          <td key={`e${ei}`}>{e}</td>
        ))}
      </tr>
    );
  });

  const onChangeResultAt = (row, column) => {
    // Don't do anything if not admin
    if (!isPlayerAdmin) {
      return;
    }

    const playResultIndex = row - 1;
    const playerIndex = column - 1;

    if (playResultIndex < 0 || playerIndex < 0) {
      return;
    }
    var newScoreValue = prompt("Change Result", game.scoreCards[playerIndex].playResults[playResultIndex].playScore);

    if (newScoreValue) {
      // Try to convert it to an int
      newScoreValue = parseInt(newScoreValue);
      if (
        isNaN(newScoreValue) ||
        newScoreValue === game.scoreCards[playerIndex].playResults[playResultIndex].playScore
      ) {
        // dont do anything if invalid number or same value
        return;
      }

      // Change the score and upload new scorecard
      var newScoreCards = game.scoreCards;
      newScoreCards[playerIndex].playResults[playResultIndex].playScore = newScoreValue;

      GameService.sendScoreCards(newScoreCards)
        .then(() => {
          if (setInfoMessage) setInfoMessage("Score sent");
        })
        .catch((e) => {
          if (e.response.data && e.response.data.error) {
            if (setErrorMessage) setErrorMessage(e.response.data.error);
          } else {
            if (setErrorMessage) setErrorMessage(`An error occured updating score cards.`);
          }
        });
    }
  };

  return (
    <div className="mb-0">
      <table
        className="table table-sm table-striped table-bordered table-hover bg-light"
        onClick={(e) => onChangeResultAt(e.target.parentNode.rowIndex, e.target.cellIndex)}
      >
        <thead className="thead-dark">
          <tr>
            <th>Oyun</th>
            {playerNameHeaders}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
};

export { ScoreCardEditor };
