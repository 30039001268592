const io = require("socket.io-client");
const { MessageTypes } = require("../shared/constants");
const SimplePeer = require("simple-peer");
import { logError } from "./logger";

/**
 * RTCPeerConnection configuration
 */
// TODO: This global will be removed when configuration is provided from outside
const RTC_CONFIGURATION = {
  iceServers: [
    {
      urls: "stun:stun.l.google.com:19302",
    },
    { urls: "turn:kozalakking.pistondustu.com:7000?transport=tcp", username: "user", credential: "root" },
    // public turn server from https://gist.github.com/sagivo/3a4b2f2c7ac6e1b5267c2f1f59ac6c6b
    // set your own servers here
    // {
    //     url: 'turn:192.158.29.39:3478?transport=udp',
    //     credential: 'JZEOEt2V3Qb0y27GRntt2u2PAYA=',
    //     username: '28224511:1379330808'
    // }
  ],
};

class ChatClient {
  constructor(options) {
    this.signalServerUrl = options.signalServerUrl;
    this.clientName = options.clientName;
    this.token = options.token;
    this.localStream = options.localStream;
    this.setVideoElement = options.setVideoElement;
    this.stopVideoElement = options.stopVideoElement;
    this.allOptions = { ...options };
    this.rtcConfiguration = RTC_CONFIGURATION; // TODO: This configuration must be flexible
    this.socket = null;
    this.otherClients = {};

    this.init();
  }

  init() {
    // Connect to the signal server
    this.socket = io.connect(this.signalServerUrl, {
      rejectUnauthorized: process.env.NODE_ENV === "development" ? false : true,
      forceNew: true,
      query: {
        token: this.token,
        clientName: this.clientName,
      },
    });

    this.registerSocketEvents();
  }

  registerSocketEvents() {
    // Log the connection success
    this.socket.on("connect", () => {});
    // Log the socket disconnect
    this.socket.on("disconnect", () => {
      logError(`client disconnected.`);
    });

    this.socket.on(MessageTypes.FromServer.RemoveClient, (id) => this.removeOtherClient(id));
    this.socket.on(MessageTypes.FromServer.NewClient, (clientData) => this.addOtherClient(clientData, null));
    this.socket.on(MessageTypes.FromServer.IdentifyClient, (data) => this.otherClientIdentified(data));
    this.socket.on(MessageTypes.FromServer.SignalFrom, (signalData) => this.signalFrom(signalData));
  }

  createSimplePeer(id, asInitiator) {
    this.otherClients[id].peer = new SimplePeer({
      initiator: asInitiator,
      stream: this.localStream,
      config: this.rtcConfiguration,
    });

    // Register the 'signal' handler for the simple-peer
    this.otherClients[id].peer.on("signal", (data) => {
      this.socket.emit(MessageTypes.FromClients.SignalTo, { signal: data, to: id });
    });

    // Register the 'stream' handler for the simple-peer
    this.otherClients[id].peer.on("stream", (stream) => {
      this.setVideoElement && this.setVideoElement(this.otherClients[id].clientName, stream);
    });
  }

  addOtherClient(clientData, identification) {
    const { id, ...restOfClientData } = clientData;

    if (identification) {
      // If identification is sent, this is a client through identification
      this.otherClients[id] = { id: id, ...identification };
      // Create the simple peer as initiator
      this.createSimplePeer(id, true);
    } else {
      // This is a new client through creation. Identify yourself
      this.otherClients[id] = { id: id, clientName: restOfClientData.clientName };
      this.socket.emit(MessageTypes.FromClients.IdentifySelf, { to: id, clientName: this.clientName });
      // Create the simple peer as NOT initiator
      this.createSimplePeer(id, false);
    }
  }

  removeOtherClient(id) {
    // If id is present, and there's a simplepeer, close it first
    if (id in this.otherClients) {
      this.stopVideoElement && this.stopVideoElement(this.otherClients[id].clientName);

      if (this.otherClients[id].peer) {
        // Destroy this peer before removing the client
        this.otherClients[id].peer.destroy();
      }
    }
    if (this.otherClients[id]) delete this.otherClients[id];
  }

  // When one of the other clients identified itself to this client
  otherClientIdentified(data) {
    // Check data validity
    if (!data.hasOwnProperty("from")) {
      logError(`Identification data is invalid.`);
      return;
    }
    // Detach from field from the data
    const { from, ...restOfData } = data;
    this.addOtherClient({ id: from }, restOfData);
  }

  // When a signal is received from other clients
  signalFrom(signalData) {
    // Check data validity
    if (!signalData.hasOwnProperty("from") || !signalData.hasOwnProperty("signal")) {
      logError(`Signal data is invalid.`);
      return;
    }

    // Check if the client is registered (sanity check)
    if (signalData.from in this.otherClients) {
      // Signal the simple peer
      this.otherClients[signalData.from].peer.signal(signalData.signal);
    } else {
      console.warn(`Signal received from unregistered client ${signalData.from}. Ignoring.`);
      return;
    }
  }
}

export default ChatClient;
