import React, { useCallback } from "react";
import capitalize from "../../helpers/capitalize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logError } from "../../helpers/logger";
import { faCouch, faSignOutAlt, faCheckCircle, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import TrioGameService from "../../services/triogame.service";

const PlayerSitArea = (props) => {
  const { showPlayerIndex, playerList, currentPlayer } = props;
  const playerToShow = playerList && playerList.length > showPlayerIndex && playerList[showPlayerIndex];
  const showOnly = playerToShow && currentPlayer.id !== playerToShow.id;

  const onSitClicked = useCallback((showPlayerIndex) => {
    TrioGameService.sitAsPlayer(showPlayerIndex).catch((e) => {
      logError(e);
    });
  }, []);

  const onReadyClicked = useCallback((isReady) => {
    TrioGameService.playerReady(isReady).catch((e) => {
      logError(e);
    });
  }, []);

  const onStandClicked = useCallback(() => {
    TrioGameService.standUp().catch((e) => {
      logError(e);
    });
  }, []);

  return playerToShow ? (
    <div className="d-flex flex-column w-100 align-items-center">
      <div className="d-flex flex-row align-items-center">
        <div className="text-white text-center" style={{ fontSize: "2rem" }}>
          {capitalize(playerToShow.name)}
        </div>
        <div className={`text-${playerToShow.isReady ? "success" : "secondary"} ml-2`}>
          <FontAwesomeIcon icon={playerToShow.isReady ? faCheckCircle : faQuestionCircle} />
        </div>
      </div>
      {!showOnly && (
        <div className="flex-row">
          <button
            className={`btn btn-sm mr-1 btn-${playerToShow.isReady ? "success" : "info"}`}
            style={{ borderRadius: "0.5em" }}
            onClick={() => onReadyClicked(!playerToShow.isReady)}
          >
            <FontAwesomeIcon icon={playerToShow.isReady ? faCheckCircle : faQuestionCircle} size="sm" /> Hazir
          </button>
          <button className="btn btn-sm btn-dark" style={{ borderRadius: "0.5em" }} onClick={onStandClicked}>
            <FontAwesomeIcon icon={faSignOutAlt} size="sm" /> Kalk
          </button>
        </div>
      )}
    </div>
  ) : (
    <button
      className="btn btn-lg btn-primary"
      style={{ borderRadius: "2em" }}
      onClick={() => onSitClicked(showPlayerIndex)}
    >
      <FontAwesomeIcon icon={faCouch} size="sm" /> Otur
    </button>
  );
};

const LobbyDisplay = (props) => {
  const { playerList, currentPlayer } = props;

  return (
    <div className="d-flex flex-column border border-info align-items-center w-100 h-100">
      {/* Top part with other players hands and game play area */}
      <div className="d-flex flex-column border" style={{ height: "70%", width: "100%" }}>
        {/* Opposite currentPlayer's hand */}
        <div className="d-flex border " style={{ height: "40%", width: "100%" }}>
          Top hand
        </div>
        {/* Left currentPlayer's hand - Game Area - Right currentPlayer's hand */}
        <div className="d-flex flex-row border " style={{ height: "60%", width: "100%" }}>
          <div className="d-flex col-3 justify-content-center border border-danger">
            <div className="align-self-center">
              <PlayerSitArea showPlayerIndex={1} playerList={playerList} currentPlayer={currentPlayer} />
            </div>
          </div>
          <div className="col border border-danger">Center</div>
          <div className="d-flex col-3 justify-content-center border border-danger">
            <div className="align-self-center">
              <PlayerSitArea showPlayerIndex={2} playerList={playerList} currentPlayer={currentPlayer} />
            </div>
          </div>
        </div>
      </div>
      {/* Bottom part with this players hand */}
      <div className="d-flex border justify-content-center" style={{ height: "30%", width: "100%" }}>
        <div className="align-self-center">
          <PlayerSitArea showPlayerIndex={0} playerList={playerList} currentPlayer={currentPlayer} />
        </div>
      </div>
    </div>
  );
};

export { LobbyDisplay };
