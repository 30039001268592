const CARD_ORDER = ["2", "3", "4", "5", "6", "7", "8", "9", "0", "J", "Q", "K", "A"];

// Returns the value of the card (regardless of suit). Larger has more value
const cardValue = (card) => {
  return CARD_ORDER.findIndex((c) => c === card.slice(0, -1));
};

const checkForsuit = (card, suit) => {
  return card[1] === suit;
};

const checkHandForSuit = (hand, suit) => {
  return hand.map((card) => checkForsuit(card, suit));
};

const kupaAlmazHandPlayables = (hand, thisRound, playedRounds) => {
  let cardsPlayability = [];

  // If playing the round, only check if hearts is ever played
  if (thisRound === undefined || thisRound.length < 1) {
    // First card of the round. All cards are playable
    // Except Hearts (if hearts are played at least once or the only suit in hand is hearts)
    const isHeartsPlayable =
      playedRounds.filter((r) => r.round.filter((c) => checkForsuit(c.card, "H")).length > 0).length > 0;

    if (isHeartsPlayable) {
      // All cards are playable
      cardsPlayability = hand.map((c) => true);
    } else {
      // All but hearts are playable
      cardsPlayability = hand.map((card) => !checkForsuit(card, "H"));
    }

    // Now check if there's at least 1 playable card
    if (cardsPlayability.every((i) => i === false)) {
      // No playable card (ie. all remaining cards are hearts and hearts is not played yet)
      // All cards are playable
      return hand.map((c) => true);
    }

    return cardsPlayability;
  }

  // Suit this round is the first played suit
  const roundsSuite = thisRound[0].card[1];

  // Mark all same suits as playable, if no playables, then all will be false
  cardsPlayability = checkHandForSuit(hand, roundsSuite);
  // If there's at least one playabale now, no need to further check
  if (cardsPlayability.some((p) => p === true)) {
    return cardsPlayability;
  }
  // Hand doesn't have the suit for this round.
  // Check if there are any hearts
  cardsPlayability = checkHandForSuit(hand, "H");
  // If there's at least one playabale now, no need to further check
  if (cardsPlayability.some((p) => p === true)) {
    return cardsPlayability;
  }
  // Hand doesn't have suit or hearts to play. Any card is playable
  return hand.map((c) => true);
};

const kizAlmazHandPlayables = (hand, thisRound, playedRounds) => {
  let cardsPlayability = [];

  // If starting the round, can play anything
  if (thisRound === undefined || thisRound.length < 1) {
    // All cards are playable
    return hand.map((c) => true);
  }

  // Suit this round is the first played suit
  const roundsSuite = thisRound[0].card[1];
  // Mark all same suits as playable, if no playables, then all will be false
  cardsPlayability = checkHandForSuit(hand, roundsSuite);
  // If there's at least one playabale now, no need to further check
  if (cardsPlayability.some((p) => p === true)) {
    // See if hand has the queen and round has bigger card. Then queen must be played
    if (hand.some((c) => c === `Q${roundsSuite}`)) {
      // Now check if round has A or K of the suit played
      if (thisRound.some((r) => r.card === `A${roundsSuite}` || r.card === `K${roundsSuite}`)) {
        // Must play the queen of this suite
        return hand.map((c) => c === `Q${roundsSuite}`);
      }
    }
    return cardsPlayability;
  }
  // Hand doesn't have the suit for this round.
  // Check if there are any queens
  cardsPlayability = hand.map((c) => c[0] === "Q");
  // If there's at least one playabale now, no need to further check
  if (cardsPlayability.some((p) => p === true)) {
    return cardsPlayability;
  }
  // Hand doesn't have suit or queens to play. Any card is playable
  return hand.map((c) => true);
};

const erkekAlmazHandPlayables = (hand, thisRound, playedRounds) => {
  let cardsPlayability = [];

  // If starting the round, can play anything
  if (thisRound === undefined || thisRound.length < 1) {
    // All cards are playable
    return hand.map((c) => true);
  }

  // Suit this round is the first played suit
  const roundsSuite = thisRound[0].card[1];
  // Mark all same suits as playable, if no playables, then all will be false
  cardsPlayability = checkHandForSuit(hand, roundsSuite);
  // If there's at least one playabale now, no need to further check
  if (cardsPlayability.some((p) => p === true)) {
    // See if hand has any 'K' or 'J' and round has bigger card. Then that must be played
    if (hand.some((c) => c === `K${roundsSuite}` || c === `J${roundsSuite}`)) {
      // Now check if round has A, K or Q of the suit played
      if (thisRound.some((r) => r.card === `A${roundsSuite}` || r.card === `K${roundsSuite}`)) {
        // Must play the K or J
        return hand.map((c) => c === `K${roundsSuite}` || c === `J${roundsSuite}`);
      } else if (thisRound.some((r) => r.card === `Q${roundsSuite}`)) {
        // Must play J if I have J
        if (hand.some((c) => c === `J${roundsSuite}`)) {
          return hand.map((c) => c === `J${roundsSuite}`);
        }
        // In this case Can play any of the suit
      }
    }
    return cardsPlayability;
  }
  // Hand doesn't have the suit for this round.
  // Check if there are any K or J
  cardsPlayability = hand.map((c) => c[0] === "K" || c[0] === "J");
  // If there's at least one playabale now, no need to further check
  if (cardsPlayability.some((p) => p === true)) {
    return cardsPlayability;
  }
  // Hand doesn't have suit or queens to play. Any card is playable
  return hand.map((c) => true);
};

const onlyCheckForSuit = (hand, thisRound, playedRounds) => {
  let cardsPlayability = [];

  // If starting the round, can play anything
  if (thisRound === undefined || thisRound.length < 1) {
    // All cards are playable
    return hand.map((c) => true);
  }

  // Suit this round is the first played suit
  const roundsSuite = thisRound[0].card[1];
  // Mark all same suits as playable, if no playables, then all will be false
  cardsPlayability = checkHandForSuit(hand, roundsSuite);
  // If there's at least one playabale now, no need to further check
  if (cardsPlayability.some((p) => p === true)) {
    return cardsPlayability;
  }
  // Hand doesn't have the suit for this round.
  // Anything can be played
  return hand.map((c) => true);
};

const rifkiHandPlayables = (hand, thisRound, playedRounds) => {
  let cardsPlayability = [];

  // If playing the round, only check if hearts is ever played
  if (thisRound === undefined || thisRound.length < 1) {
    // First card of the round. All cards are playable
    // Except Hearts (if hearts are played at least once or the only suit in hand is hearts)
    const isHeartsPlayable =
      playedRounds.filter((r) => r.round.filter((c) => checkForsuit(c.card, "H")).length > 0).length > 0;

    if (isHeartsPlayable) {
      // All cards are playable
      cardsPlayability = hand.map((c) => true);
    } else {
      // All but hearts are playable
      cardsPlayability = hand.map((card) => !checkForsuit(card, "H"));
    }

    // Now check if there's at least 1 playable card
    if (cardsPlayability.every((i) => i === false)) {
      // No playable card (ie. all remaining cards are hearts and hearts is not played yet)
      // All cards are playable
      return hand.map((c) => true);
    }

    return cardsPlayability;
  }

  // Suit this round is the first played suit
  const roundsSuite = thisRound[0].card[1];

  // Mark all same suits as playable, if no playables, then all will be false
  cardsPlayability = checkHandForSuit(hand, roundsSuite);
  // If there's at least one playabale now, no need to further check
  if (cardsPlayability.some((p) => p === true)) {
    return cardsPlayability;
  }
  // Hand doesn't have the suit for this round.
  // Check if we have KH
  if (hand.includes("KH")) {
    // KH must be played
    return hand.map((c) => c === "KH");
  }

  // Check if there are any hearts
  cardsPlayability = checkHandForSuit(hand, "H");
  // If there's at least one playabale now, no need to further check
  if (cardsPlayability.some((p) => p === true)) {
    return cardsPlayability;
  }
  // Hand doesn't have suit or hearts to play. Any card is playable
  return hand.map((c) => true);
};

const TRUMP_MAPPINGS = Object.freeze({
  KUPA: "H",
  KARO: "D",
  MAÇA: "S",
  SİNEK: "C",
});

const kozHandPlayables = (hand, playType, thisRound, playedRounds) => {
  // start with all cards as playable
  let cardsPlayability = hand.map((c) => true);
  const currentTrump = TRUMP_MAPPINGS[playType.toLocaleUpperCase("TR").replace("KOZ ", "")] || null;
  if (!currentTrump) {
    // Something wrong parsing current play
    console.error(`something wrong parsing current play >${playType}<`);
    return hand.map((c) => true);
  }
  // If not starting the round check for the suit
  if (thisRound !== undefined && thisRound.length > 0) {
    // Suit this round is the first played suit
    const roundsSuite = thisRound[0].card[1];
    // Only this suit can be played
    cardsPlayability = checkHandForSuit(hand, roundsSuite);
    // Check if any cards are playable
    if (cardsPlayability.some((p) => p)) {
      if (roundsSuite === currentTrump) {
        const largestThisRound = Math.max(...thisRound.map((r) => cardValue(r.card)));
        // Current suite can be trump. If so we must increase the hand
        cardsPlayability = cardsPlayability.map((p, i) => {
          // Only check playable ones
          if (p) {
            // Check the card at this index, compare to the largest played card
            const currentCardValue = cardValue(hand[i]);
            if (currentCardValue > largestThisRound) {
              return true;
            }
          }
          return false;
        });
        // If here are no cards are playable, then this means all the trumps in hand are less than last card. So all can be played
        if (cardsPlayability.every((p) => p === false)) {
          cardsPlayability = checkHandForSuit(hand, roundsSuite);
        }
      }
      return cardsPlayability;
    }
    // If here, this means we don't have the current suit in hand. Check if we have trumps
    cardsPlayability = checkHandForSuit(hand, currentTrump);
    // Check if any cards are playable
    if (cardsPlayability.some((p) => p)) {
      return cardsPlayability;
    }
    // If here it means no cards from played suit or trump.
    // All cards are playable
    return hand.map((c) => true);
  }

  // Check if koz is played
  const playedTrumps = playedRounds.filter((pr) => pr.round.filter((r) => r.card.endsWith(currentTrump)).length > 0);

  if (playedTrumps.length > 0) {
    // Koz can be played
    // All cards are playable
    return hand.map((c) => true);
  } else {
    // If here, this means we don't have the current suit in hand. Check if we have trumps
    cardsPlayability = checkHandForSuit(hand, currentTrump).map((p) => !p);

    // Check if hand has A, K and Q of trumps
    if (
      hand.find((c) => c === `A${currentTrump}`) &&
      hand.find((c) => c === `K${currentTrump}`) &&
      hand.find((c) => c === `Q${currentTrump}`)
    ) {
      // The also these can be played
      const aIndex = hand.findIndex((c) => c === `A${currentTrump}`);
      const kIndex = hand.findIndex((c) => c === `K${currentTrump}`);
      const qIndex = hand.findIndex((c) => c === `Q${currentTrump}`);
      cardsPlayability[aIndex] = true;
      cardsPlayability[kIndex] = true;
      cardsPlayability[qIndex] = true;
    }

    // Now check if any of the cards are playable, if not, means only trumps left in hand and no other playable card
    // make all playable
    if (!cardsPlayability.some((p) => p)) {
      return hand.map((c) => true);
    }
  }

  return cardsPlayability;
};

const determinePlayableCards = (hand, playType, thisRound, playedRounds) => {
  const functionMappings = {
    "Kupa Almaz": kupaAlmazHandPlayables,
    "Kız Almaz": kizAlmazHandPlayables,
    "Erkek Almaz": erkekAlmazHandPlayables,
    "El Almaz": onlyCheckForSuit,
    "Son İki": onlyCheckForSuit,
    Rıfkı: rifkiHandPlayables,
    Koz: kozHandPlayables,
  };

  if (playType in functionMappings) {
    return functionMappings[playType](hand, thisRound, playedRounds);
  } else if (playType.toUpperCase().startsWith("KOZ")) {
    return kozHandPlayables(hand, playType, thisRound, playedRounds);
  }

  return null;
};

export { determinePlayableCards };
