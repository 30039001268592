import axios from "axios";

const API_URL = "/api";
const LOCAL_STORAGE_KEY = "player";

class AuthService {
  login(name, password) {
    return axios
      .post(`${API_URL}/signin`, {
        name,
        password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          // Save to local storage as "player"
          localStorage.setItem(
            LOCAL_STORAGE_KEY,
            JSON.stringify(response.data)
          );
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  }

  register(name, email, password) {
    return axios.post(`${API_URL}/signup`, {
      name,
      email,
      password,
    });
  }

  getCurrentPlayer() {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  }
}

export default new AuthService();
