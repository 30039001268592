import React, { useCallback } from "react";
import GameService from "../services/game.service";
import { logError } from "../helpers/logger";
import { GiHearts, GiSpades, GiClubs, GiDiamonds, GiCardKingHearts } from "react-icons/gi";
const kozIcon = {
  Maça: <GiSpades style={{ color: "black", fontSize: "1.2em" }} />,
  Karo: <GiDiamonds style={{ color: "red", fontSize: "1.2em" }} />,
  Kupa: <GiHearts style={{ color: "red", fontSize: "1.2em" }} />,
  Sinek: <GiClubs style={{ color: "black", fontSize: "1.2em" }} />,
};

const PlaySelector = (props) => {
  const { game, playerIndex } = props;
  const isCouplesGame = game && game.gameType === "esliking";

  const playersScorecard =
    game && game.scoreCards && game.scoreCards.find((sc) => sc.playerId === game.playerList[playerIndex].id);
  const partnerIndex = isCouplesGame && (playerIndex + 2) % 4;
  const partnersScorecard =
    isCouplesGame && game.scoreCards && game.scoreCards.find((sc) => sc.playerId === game.playerList[partnerIndex].id);

  // Calculate the remaining negative plays
  var remainingPlays = {};

  // prettier-ignore
  let totalCezaPlays =
    ((playersScorecard && playersScorecard.plays) ? playersScorecard.plays.filter((p) => p === "c").length : 0) + 
    ((partnersScorecard && partnersScorecard.plays) ? partnersScorecard.plays.filter((p) => p === "c").length : 0);
  // prettier-ignore
  let totalKozPlays =
    ((playersScorecard && playersScorecard.plays) ? playersScorecard.plays.filter((p) => p === "k").length : 0) + 
    ((partnersScorecard && partnersScorecard.plays) ? partnersScorecard.plays.filter((p) => p === "k").length : 0);

  // If more ceza can be played add to remaining plays
  if (totalCezaPlays < (isCouplesGame ? 6 : 3)) {
    // Reduce the counts based on played negative hands
    playersScorecard &&
      playersScorecard.playResults &&
      Object.values(game.allPlayTypes).forEach((p) => {
        // Skip Koz plays
        if (p.includes("Koz")) return;
        // Find how many of this play is played from the scorecard
        const thisPlayPlayed = playersScorecard.playResults.filter((r) => r.playType === p).length;
        if (thisPlayPlayed < 2) remainingPlays[p] = thisPlayPlayed;
      });
  }
  // If more koz can be played add to remaining plays
  // Also check if first 4 plays (singles game)

  if (totalKozPlays < (isCouplesGame ? 4 : 2)) {
    if (isCouplesGame || game.currentPlayNo >= 4) {
      Object.values(game.allPlayTypes)
        .filter((pt) => pt.includes("Koz"))
        .sort()
        .forEach((koz) => (remainingPlays[koz] = (isCouplesGame ? 4 : 2) - totalKozPlays));
    }
  }

  const onStartPlay = useCallback(
    (selectedPlay) => {
      // Find the key for selected play
      const keyForPlay = Object.keys(game.allPlayTypes).find((key) => game.allPlayTypes[key] === selectedPlay);
      GameService.startPlay({ type: keyForPlay }).catch((e) => {
        /* Ignore error */
        if (e.response.data && e.response.data.error) {
          logError(e.response.data.error);
        } else {
          logError(`An error occured starting the play.`);
        }
      });
    },
    [game.allPlayTypes]
  );

  if (!game || !game.allPlayTypes) {
    return <></>;
  }

  return (
    <div className="d-flex flex-row w-100 h-100 align-items-center">
      <div className="d-flex flex-column" style={{ width: "60%" }}>
        {Object.keys(remainingPlays)
          .sort()
          .map(
            (k, index) =>
              !k.includes("Koz") && (
                <button
                  className="btn btn-sm btn-success mb-1 p-1"
                  style={{ borderRadius: "1em", fontSize: "1em" }}
                  key={`cezabtn-${index}`}
                  onClick={() => onStartPlay(k)}
                >{`${k} [${2 - remainingPlays[k]}]`}</button>
              )
          )}
      </div>
      <div className="d-flex flex-column" style={{ width: "40%" }}>
        {Object.keys(remainingPlays)
          .sort()
          .map(
            (k, index) =>
              k.includes("Koz") && (
                <button
                  className="btn btn-sm btn-info mb-1 ml-1 p-1"
                  style={{ borderRadius: "1em", fontSize: "1em" }}
                  key={`kozbtn-${index}`}
                  onClick={() => onStartPlay(k)}
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <div style={{fontStyle: "italic"}}>{k}</div>
                    <div className="d-flex ml-1">{kozIcon[k.replace("Koz ", "")]}</div>
                  </div>
                </button>
              )
          )}
      </div>
    </div>
  );
};

export { PlaySelector };
