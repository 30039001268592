import React, { useCallback, useEffect, useRef, useState } from "react";

import GameService from "../services/game.service";
import AuthService from "../services/auth.service";
import { GameTable } from "./GameTable";
import "./board-game.component.css";
import { bindToEvents } from "../helpers/socket";
import { TrioKingTable } from "./trioking/TrioKingTable";

const AUTO_REFRESH_INTERVAL = 5000; // Auto refresh every 5 seconds

const BoardGame = (props) => {
  const [player, setPlayer] = useState(null);
  const intervalId = useRef(null);
  const socket = useRef(null);
  const [game, setGame] = useState({ state: "idle" });

  const refreshComponent = useCallback(() => {
    clearInterval(intervalId.current);

    // Set a periodic interval
    intervalId.current = setInterval(refreshComponent, AUTO_REFRESH_INTERVAL);
    GameService.getGameState()
      .then((response) => {
        setGame(response.data.game);
      })
      .catch((e) => {
        // Dont do anything and show login page
        // AuthService.logout();
        // this.props.history.push("/login");
        // this.props.history.go();
        return;
      });
  }, []);

  // This event is sent every time game is updated
  const onGameChangeEvent = useCallback((eventData) => {
    // Refresh component when game changes
    refreshComponent();
  }, []);

  useEffect(() => {
    setPlayer(AuthService.getCurrentPlayer());
    refreshComponent();
    // Bind event Socket.IO handler
    socket.current = bindToEvents({ onGameChanged: onGameChangeEvent }, `${window.location.host}`);

    // Clear intervalId on return
    return () => {
      // Remove listeners from socket. IS this really needed??
      socket.current && socket.current.removeAllListeners();
      // Remove timer
      intervalId.current && clearInterval(intervalId.current);
    };
  }, []);

  return (
    <div className="d-flex justify-content-center w-100 p-0" style={{ height: "91vh" }}>
      {game &&
        game.gameType &&
        (game.gameType === "trioking" ? (
          <TrioKingTable game={game} currentPlayer={player} />
        ) : (
          <GameTable game={game} player={player} />
        ))}
      {player == null && <a href="/login"> Lütfen önce giriş yapın </a>}
    </div>
  );
};

export default BoardGame;
