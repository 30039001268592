import React from "react";
import { ImageForCard, sortHand } from "./Card";

const filterCardsForKoz = (ownedRounds) => {
  return (
    <div
      className="d-flex flex-row text-light h-100"
      data-toggle="tooltip"
      title={ownedRounds.length > 0 ? `Alınan El: ${ownedRounds.length}` : undefined}
    >
      {ownedRounds.map((_, i) => (
        <ImageForCard className="h-100" style={{ marginLeft: i > 0 ? "-1em" : "" }} key={`i${i}`} />
      ))}
    </div>
  );
};

const filterCardsForKupaAlmaz = (ownedRounds) => {
  // Find Hearts in owned rounds
  let heartsInRounds = [];
  ownedRounds.forEach((r) => {
    r.round.forEach((c) => {
      if (c.card[1] === "H") {
        // found hearts card
        heartsInRounds.push(c.card);
      }
    });
  });
  heartsInRounds = sortHand(heartsInRounds);

  return (
    <div
      className="d-flex flex-row text-light h-100"
      data-toggle="tooltip"
      title={heartsInRounds.length > 0 ? `Alınan Kupa: ${heartsInRounds.length}` : undefined}
    >
      {heartsInRounds.map((r, i) => (
        <ImageForCard className="h-100" card={r} style={{ marginLeft: i > 0 ? "-1em" : "" }} key={`i${i}`} />
      ))}
    </div>
  );
};

const filterCardsForKizAlmaz = (ownedRounds) => {
  // Find Queens in owned rounds
  let queensInRounds = [];
  ownedRounds.forEach((r) => {
    r.round.forEach((c) => {
      if (c.card[0] === "Q") {
        // found queen card
        queensInRounds.push(c.card);
      }
    });
  });
  queensInRounds = sortHand(queensInRounds);

  return (
    <div
      className="d-flex flex-row text-light h-100"
      data-toggle="tooltip"
      title={queensInRounds.length > 0 ? `Alınan Kız: ${queensInRounds.length}` : undefined}
    >
      {queensInRounds.map((r, i) => (
        <ImageForCard className="h-100 pr-1" card={r} key={`i${i}`} />
      ))}
    </div>
  );
};

const filterCardsForErkekAlmaz = (ownedRounds) => {
  // Find Jacks and Kings in owned rounds
  let jacksAndKings = [];
  ownedRounds.forEach((r) => {
    r.round.forEach((c) => {
      if (c.card[0] === "J" || c.card[0] === "K") {
        // found queen card
        jacksAndKings.push(c.card);
      }
    });
  });
  jacksAndKings = sortHand(jacksAndKings);

  return (
    <div
      className="d-flex flex-row text-light h-100"
      data-toggle="tooltip"
      title={jacksAndKings.length > 0 ? `Alınan Erkek: ${jacksAndKings.length}` : undefined}
    >
      {jacksAndKings.map((r, i) => (
        <ImageForCard className="h-100 pr-1" card={r} key={`i${i}`} style={{ marginLeft: i > 0 ? "-1em" : "" }} />
      ))}
    </div>
  );
};

const filterCardsForSonIki = (ownedRounds, playedRounds, playerId) => {
  if (playedRounds.length < 11) {
    return <></>;
  }
  if (ownedRounds.length === 0) {
    return <></>;
  }
  // Check the last 2 rounds and find player's ID
  let lastRoundsCount = 0;
  for (let i = 11; i < playedRounds.length; i++) {
    if (playedRounds[i].ownedByPlayerId === playerId) {
      lastRoundsCount++;
    }
  }
  return (
    <div
      className="d-flex flex-row text-light h-100"
      data-toggle="tooltip"
      title={lastRoundsCount > 0 ? `Alınan El: ${lastRoundsCount}` : undefined}
    >
      {[...Array(lastRoundsCount).keys()].map((_, i) => (
        <ImageForCard className="h-100 ml-2" key={`i${i}`} />
      ))}
    </div>
  );
};

const filterCardsForRifki = (ownedRounds) => {
  // Find King of Hearts in owned rounds
  const doIHaveKingOfHearts = ownedRounds.filter((r) => r.round.filter((c) => c.card === "KH").length > 0).length > 0;

  if (doIHaveKingOfHearts) {
    return (
      <div className="d-flex flex-row text-light h-100">
        <ImageForCard className="h-100" card="KH" />
      </div>
    );
  } else {
    return <></>;
  }
};

const getCardsToShowForPlayer = (player, playedRounds, currentPlay) => {
  const ownedRounds = playedRounds ? playedRounds.filter((r) => r.ownedByPlayerId === player.id) : [];

  if (currentPlay === "El Almaz" || currentPlay.includes("Koz")) {
    return filterCardsForKoz(ownedRounds);
  } else if (currentPlay === "Kupa Almaz") {
    return filterCardsForKupaAlmaz(ownedRounds);
  } else if (currentPlay === "Kız Almaz") {
    return filterCardsForKizAlmaz(ownedRounds);
  } else if (currentPlay === "Erkek Almaz") {
    return filterCardsForErkekAlmaz(ownedRounds);
  } else if (currentPlay === "Son İki") {
    return filterCardsForSonIki(ownedRounds, playedRounds, player.id);
  } else if (currentPlay === "Rıfkı") {
    return filterCardsForRifki(ownedRounds);
  }

  return <></>;
};

const OwnedRoundsStatus = (props) => {
  const { game, player } = props;

  if (!game || !player) {
    return <></>;
  }

  return (
    <div className="d-flex flex-row p-1 justify-content-center h-100">
      {getCardsToShowForPlayer(player, game.playedRounds, game.currentPlay)}
    </div>
  );
};

export { OwnedRoundsStatus };
