const TRIOKING_GAMETYPE = "trioking";
const TRIOKING_NOOFPLAYERS = 3;

const TrioKingGameStates = {
  IDLE: "idle",
  PLAYERSINLOBBY: "playersinlobby",
  WAITFORPLAYTYPE: "waitforplaytype",
  PLAYINGROUND: "playinground",
  GAMEOVER: "gameover",
};

const TrioKingPlayTypes = {
  ELALMAZ: "El Almaz",
  RIFKI: "Rıfkı",
  KIZALMAZ: "Kız Almaz",
  ERKEKALMAZ: "Erkek Almaz",
  SONIKI: "Son İki",
  KUPAALMAZ: "Kupa Almaz",
  KOZKUPA: "Koz Kupa",
  KOZMACA: "Koz Maça",
  KOZSINEK: "Koz Sinek",
  KOZKARO: "Koz Karo",
  SANZATU: "Sanzatu",
};

const MessageTypes = Object.freeze({
  FromClients: {
    IdentifySelf: "MessageTypes_FromClients_IdentifySelf",
    SignalTo: "MessageTypes_FromClients_SignalTo",
  },
  FromServer: {
    NewClient: "MessageTypes_FromServer_NewClient",
    IdentifyClient: "MessageTypes_FromServer_IdentifyClient",
    SignalFrom: "MessageTypes_FromServer_SignalFrom",
    RemoveClient: "MessageTypes_RemoveClient",
  },
});

module.exports = {
  TRIOKING_GAMETYPE,
  TRIOKING_NOOFPLAYERS,
  TrioKingGameStates,
  TrioKingPlayTypes,
  MessageTypes,
};
