import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "/api";
const GAME_API_URL = `${API_URL}/game`;

class GameService {
  getGameState() {
    return axios.get(`${GAME_API_URL}`, { headers: authHeader() });
  }

  startGame(playerIdList, gameType) {
    return axios.post(`${GAME_API_URL}/start`, { ...playerIdList, gameType: gameType }, { headers: authHeader() });
  }

  startPlay(playType) {
    return axios.post(`${GAME_API_URL}/startPlay`, playType, { headers: authHeader() });
  }

  goToNextPlay(playType) {
    return axios.post(`${GAME_API_URL}/nextPlay`, playType, { headers: authHeader() });
  }

  playCard(playerId, card) {
    return axios.post(`${GAME_API_URL}/playCard`, { playerId: playerId, card: card }, { headers: authHeader() });
  }

  giveRoundTo(toPlayerId) {
    return axios.post(`${GAME_API_URL}/giveRound`, { toPlayerId: toPlayerId }, { headers: authHeader() });
  }

  saveGameToFile(fileName) {
    return axios.post(`${GAME_API_URL}/saveGame`, { fileName: fileName }, { headers: authHeader() });
  }

  loadGameFromFile(fileName) {
    return axios.post(`${GAME_API_URL}/loadGame`, { fileName: fileName }, { headers: authHeader() });
  }

  sendScoreCards(scoreCards) {
    return axios.post(`${GAME_API_URL}/updateScoreCards`, { scoreCards: scoreCards }, { headers: authHeader() });
  }

  putLastCardBack() {
    return axios.post(`${GAME_API_URL}/putBackCard`, {}, { headers: authHeader() });
  }
}

export default new GameService();
