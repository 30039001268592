import React from "react";
import { sortHand } from "../Card";
import capitalize from "../../helpers/capitalize";
import { HandDisplay } from "./CardDisplay";

// Returns the index of next player
const nextPlayerIndex = (thisPlayerIndex) => {
  return (thisPlayerIndex + 1) % 3;
};

const getPlayer = (playerIndex, game) => {
  return {
    index: playerIndex, // If table index is <0
    player: playerIndex < 0 ? { id: -1, name: "Yer" } : game.playerList[playerIndex], // If table, return null as player info
    hand: playerIndex < 0 ? sortHand(game.tableHand) : sortHand(game.playerHands[playerIndex]), // If table, return table hand
  };
};

const PlayerInfoDisplay = (props) => {
  const { playerInfo } = props;

  return (
    <div className="d-flex flex-column w-100 h-100 align-items-center">
      <div className="border" style={{ border: "1px white solid", borderRadius: "1em", width: "10em" }}>
        <p className="h4 text-white text-center p-2 m-0">{capitalize(playerInfo.player.name)}</p>
      </div>
      <div className="border border-warning flex-fill w-100">
        <p className="text-white text-center">Content</p>
      </div>
    </div>
  );
};

const TableHandDisplay = (props) => {
  const { playerInfo } = props;

  return (
    <div className="d-flex flex-column w-100 h-100 align-items-center">
      <div className="border" style={{ border: "1px white solid", borderRadius: "1em", width: "10em" }}>
        <p className="h4 text-white text-center p-2 m-0">{capitalize(playerInfo.player.name)}</p>
      </div>
      <div className="border border-warning flex-fill w-100 justify-content-center">
        <HandDisplay hand={playerInfo.hand} twoRows={!props.topArea} small />
      </div>
    </div>
  );
};

const TrioPlayArea = (props) => {
  const { game, currentPlayer } = props;

  const currentPlayerIndex = game.playerList.findIndex((p) => p.id === currentPlayer.id);
  const isViewingOnly = currentPlayerIndex < 0;

  if (isViewingOnly) {
    return (
      <div className="d-flex h-100 justify-content-center align-items-center">
        <h1>Viewing only</h1>
      </div>
    );
  }

  const playersOnTable = [
    getPlayer(game.playPicker, game), // [0] ==> Playpicker
    getPlayer(nextPlayerIndex(game.playPicker), game), // [1] ==> Player on the left of playpicker
    getPlayer(-1, game), // [2]] ==> Tables hand
    getPlayer(nextPlayerIndex(game.playPicker + 1), game), // [3] ==> Player on the right of playpicker
  ];

  // Index of logged in player in the sorted players list
  const actualIndex = playersOnTable.findIndex((p) => p.index === currentPlayerIndex);

  // Contains all info about the logged in player
  const loggedInPlayer = playersOnTable[actualIndex];
  const leftPlayer = playersOnTable[(actualIndex + 1) % 4];
  const topPlayer = playersOnTable[(actualIndex + 2) % 4];
  const rightPlayer = playersOnTable[(actualIndex + 3) % 4];

  return (
    <div className="d-flex flex-column w-100 h-100">
      <div className="d-flex flex-row justify-content-center align-items-center border" style={{ height: "30%" }}>
        {topPlayer.index < 0 ? (
          <TableHandDisplay playerInfo={topPlayer} topArea />
        ) : (
          <PlayerInfoDisplay playerInfo={topPlayer} />
        )}
      </div>
      <div className="d-flex flex-row justify-content-center align-items-center border" style={{ height: "35%" }}>
        <div className="h-100 border border-success" style={{ width: leftPlayer.index < 0 ? "40%" : "25%" }}>
          {leftPlayer.index < 0 ? (
            <TableHandDisplay playerInfo={leftPlayer} />
          ) : (
            <PlayerInfoDisplay playerInfo={leftPlayer} />
          )}
        </div>
        <div className="h-100 border border-success" style={{ width: "35%" }}>
          Tabletop Area
        </div>
        <div className="h-100 border border-success" style={{ width: rightPlayer.index < 0 ? "40%" : "25%" }}>
          {rightPlayer.index < 0 ? (
            <TableHandDisplay playerInfo={rightPlayer} />
          ) : (
            <PlayerInfoDisplay playerInfo={rightPlayer} />
          )}
        </div>
      </div>
      <div
        className="d-flex flex-column justify-content-center align-items-center border border-danger"
        style={{ height: "35%" }}
      >
        <div className="h1 text-white mb-2">{capitalize(currentPlayer.name)}</div>
        <HandDisplay hand={loggedInPlayer.hand} />
      </div>
    </div>
  );
};

export { TrioPlayArea };
