import React, { useRef, useEffect } from "react";
import "./PlayerAreas.css";

const VideoClient = (props) => {
  const { isPlayersTurn, videoElementId } = props;

  return (
    <div className="videoBox">
      <video
        className={`${isPlayersTurn ? "playing" : ""}`}
        playsInline
        autoPlay
        poster="videoposter.png"
        id={videoElementId}
      ></video>
    </div>
  );
};

export default VideoClient;
