import React from "react";
import { faHandPointUp, faHandPointLeft, faHandPointDown, faHandPointRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ImageForCard } from "./Card";
import kozalakImage from "../assets/imgs/pinecone.png";

// prettier-ignore
const HandPointUp   = <FontAwesomeIcon icon={faHandPointUp} className="text-light mt-1" style={{ fontSize: "3em" }} />;
// prettier-ignore
const HandPointLeft = <FontAwesomeIcon icon={faHandPointLeft} className="text-light" style={{ fontSize: "3em" }} />
// prettier-ignore
const HandPointRight = <FontAwesomeIcon icon={faHandPointRight} className="text-light" style={{ fontSize: "3em" }} />;
// prettier-ignore
const HandPointDown = <FontAwesomeIcon icon={faHandPointDown} className="text-light mb-1" style={{ fontSize: "3em" }} />

const getCardForPlayer = (round, playerId, xAlign) => {
  // Check if this player played this round
  const foundCard = round.find((c) => c.playerId === playerId);
  if (!foundCard) {
    return null;
  }

  return (
    <ImageForCard
      card={foundCard.card}
      className={`${xAlign && "mx-auto"}`}
      style={{ height: "5em", width: "3.5em" }}
    />
  );
};

const TableTop = (props) => {
  const { whosTurn, playerIndex, currentRound, players } = props;

  const leftPlayerIndex = (playerIndex + 1) % 4;
  const topPlayerIndex = (playerIndex + 2) % 4;
  const rightPlayerIndex = (playerIndex + 3) % 4;

  // Determine who's turn
  // If current round is played, it's nobody turn
  const nobodysTurn = whosTurn < 0 || currentRound.length === 4;
  const isMyTurn = !nobodysTurn && whosTurn === playerIndex;
  const isLeftPlayersTurn = !nobodysTurn && whosTurn === leftPlayerIndex;
  const isTopPlayersTurn = !nobodysTurn && whosTurn === topPlayerIndex;
  const isRightPlayersTurn = !nobodysTurn && whosTurn === rightPlayerIndex;

  return (
    <div className="d-flex flex-column h-100 w-100 border" style={{borderRadius: "5rem"}}>
      <div className="d-flex align-self-center justify-content-center w-100" style={{ height: "33%" }}>
        {isTopPlayersTurn ? (
          <div className="mb-auto">{HandPointUp}</div>
        ) : (
          <div className="mt-auto">
            {getCardForPlayer(currentRound, players[topPlayerIndex] && players[topPlayerIndex].id, true)}
          </div>
        )}
      </div>
      <div className="d-flex flex-row align-self-center w-100" style={{ height: "33%" }}>
        <div className="d-flex flex-row align-items-center ml-4" style={{ width: "40%" }}>
          {isLeftPlayersTurn ? (
            HandPointLeft
          ) : (
            <div className="ml-auto">
              {getCardForPlayer(currentRound, players[leftPlayerIndex] && players[leftPlayerIndex].id, false)}
            </div>
          )}
        </div>
        <div className="d-flex align-items-center" style={{ width: "20%" }}>
          {/* prettier-ignore */}
          <img src={kozalakImage} height="40px" width="40px" alt="Kozalak" className="m-auto border border-light rounded-circle p-1" />
        </div>
        <div className="d-flex align-items-center mr-4" style={{ width: "40%" }}>
          {isRightPlayersTurn ? (
            <div className="ml-auto">{HandPointRight}</div>
          ) : (
            getCardForPlayer(currentRound, players[rightPlayerIndex] && players[rightPlayerIndex].id, false)
          )}
        </div>
      </div>
      <div className="d-flex align-self-center" style={{ height: "33%" }}>
        {isMyTurn ? (
          <div className="mt-auto">{HandPointDown}</div>
        ) : (
          getCardForPlayer(currentRound, players[playerIndex] && players[playerIndex].id, true)
        )}
      </div>
    </div>
  );
};

export { TableTop };
