import React from 'react';

const ErrorMessageDisplay = (props) => {
  return <div className="container-fluid alert alert-danger" onClick={props.onClear}>
    {props.message}
  </div>
}

const InfoMessageDisplay = (props) => {
  return <div className="container-fluid alert alert-info" onClick={props.onClear}>
    {props.message}
  </div>
}

export {ErrorMessageDisplay, InfoMessageDisplay};