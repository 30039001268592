import React from "react";
import { ImageForCard } from "./Card";

const PlayedRoundsDisplay = (props) => {
  const { game, player } = props;
  if (!game) return <></>;

  const isCouplesGame = game && game.gameType === "esliking";
  const playerIndex = game && player && game.playerList.findIndex((p) => p.id === player.id);
  const partnerIndex = isCouplesGame ? playerIndex != null && (playerIndex + 2) % 4 : null;
  const partner = partnerIndex != null && game.playerList[partnerIndex];

  // Find the players owned hand
  const ownedRounds =
    game &&
    player &&
    game.playedRounds &&
    game.playedRounds.filter((r) => {
      // If couples game, also return partners owned rounds
      if (isCouplesGame) {
        return r.ownedByPlayerId === player.id || r.ownedByPlayerId === partner.id;
      }
      return r.ownedByPlayerId === player.id;
    });

  return (
    <div className="d-flex flex-column">
      <h2>
        {ownedRounds &&
          ownedRounds.map((r, rIndex) => {
            const cards = r.round.map((t, tIndex) => {
              return <ImageForCard card={t.card} className="h-100 mx-1" key={`t${tIndex}`} />;
            });
            return (
              <div
                className="d-flex border-secondary rounded p-1"
                key={`r${rIndex}`}
                style={{
                  height: "2em",
                  margin: "2px",
                  border: r.ownedByPlayerId === player.id ? "2px solid " : "1px dashed ",
                }}
              >
                {cards}
              </div>
            );
          })}
      </h2>
    </div>
  );
};

export { PlayedRoundsDisplay };
