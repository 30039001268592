import React from "react";

const NegativeHandsStats = (props) => {
  const { game } = props;
  // Calculate the remaining negative plays
  var playedNegativePlays = {};

  // Initialize negative play counts
  game &&
    game.allPlayTypes &&
    Object.values(game.allPlayTypes)
      .sort()
      .forEach((p) => {
        if (!p.includes("Koz")) {
          // each negative play can be played twice
          playedNegativePlays[p] = 0;
        }
      });

  // Reduce the counts based on played negative hands
  game &&
    game.scoreCards &&
    game.scoreCards.length > 0 &&
    game.scoreCards[0].playResults &&
    Object.keys(playedNegativePlays).forEach((p) => {
      // Find how many of this play is played from the scorcard
      const thisPlayPlayed = game.scoreCards[0].playResults.filter((r) => r.playType === p).length;
      playedNegativePlays[p] = thisPlayPlayed;
    });

  return (
    <div className="d-flex flex-column px-2 text-info small">
      <div className="align-self-center text-light">
        <b>Oynanan Cezalar</b>
      </div>
      <div className="d-flex flex-row">
        <div className="d-flex flex-column">
          {Object.keys(playedNegativePlays).map((p, index) => {
            // Only first 3 negative hands
            if (index > 2) {
              return null;
            }
            return (
              <div className="d-flex flex-row" key={`p${index}`}>
                {playedNegativePlays[p] === 2 ? (
                  <del>
                    <span>{p}:</span>
                    <span className="text-light ml-1">{playedNegativePlays[p]}</span>
                  </del>
                ) : (
                  <>
                    <span>{p}:</span>
                    <span className="text-light ml-1">{playedNegativePlays[p]}</span>
                  </>
                )}
              </div>
            );
          })}
        </div>
        <div className="d-flex flex-column ml-4">
          {Object.keys(playedNegativePlays).map((p, index) => {
            // Only last 3 negative hands
            if (index < 3) {
              return null;
            }
            return (
              <div key={`p${index}`}>
                {playedNegativePlays[p] === 2 ? (
                  <del>
                    {p}: <span className="text-light ml-1">{playedNegativePlays[p]}</span>
                  </del>
                ) : (
                  <>
                    {p}: <span className="text-light ml-1">{playedNegativePlays[p]}</span>
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export { NegativeHandsStats };
