import socketIOClient from "socket.io-client";

const GAME_CHANGED_EVENT = "GameChanged";

const bindToEvents = (eventCallbacks, socketIoEndpoint = "/") => {
  const socket = socketIOClient(socketIoEndpoint);
  socket.on(GAME_CHANGED_EVENT, eventCallbacks.onGameChanged);
  return socket;
};

export { bindToEvents };
