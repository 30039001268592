import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import kozalakImage from "./assets/imgs/pinecone.png";

import AuthService from "./services/auth.service";

import Login from "./components/login.component";
import Profile from "./components/profile.component";
import BoardGame from "./components/board-game.component";
import BoardAdmin from "./components/board-admin.component";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAdminBoard: false,
      currentPlayer: undefined,
    };
  }

  componentDidMount() {
    const player = AuthService.getCurrentPlayer();

    if (player) {
      this.setState({
        currentPlayer: player,
        showAdminBoard: player.roles.includes("ROLE_ADMIN"),
      });
    }
  }

  onLogout = () => {
    AuthService.logout();
    window.location.href = "/login";
  };

  render() {
    const { currentPlayer, showAdminBoard } = this.state;

    return (
      <Router>
        <nav className="navbar navbar-expand navbar-dark bg-dark py-0">
          <Link to={"/"} className="navbar-brand">
            <img
              src={kozalakImage}
              height="38px"
              className="mr-2 border border-light rounded-circle p-1"
              alt="Kozalak"
            />
            Kozalak King
          </Link>
          <div className="navbar-nav mr-auto">
            {showAdminBoard && (
              <li className="nav-item">
                <Link to={"/admin"} className="nav-link">
                  Yönetici
                </Link>
              </li>
            )}
          </div>

          {currentPlayer ? (
            <div className="d-flex navbar-nav ml-auto flex-row align-items-center">
              <div className="nav-item">
                <Link to={"/profile"} className="nav-link h3 m-0 text-light font-weight-bolder">
                  {currentPlayer.name.toLocaleUpperCase("tr-TR")}
                </Link>
              </div>
              <button className="btn btn-sm btn-secondary d-flex align-items-center py-0" onClick={this.onLogout}>
                Çıkış
                <FontAwesomeIcon icon={faSignOutAlt} className="ml-2" />
              </button>
            </div>
          ) : (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/login"} className="nav-link">
                  Giriş
                </Link>
              </li>
            </div>
          )}
        </nav>

        <div className="d-flex mt-1">
          <Switch>
            <Route exact path={["/", "/game"]} component={BoardGame} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/admin" component={BoardAdmin} />
            <Route exact path="/profile" component={Profile} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
