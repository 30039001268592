import React, { Component } from "react";
import AuthService from "../services/auth.service";
import "./login.component.css";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      password: "",
      loading: false,
      message: "",
    };
  }

  onChangeName = (e) => {
    this.setState({ name: e.target.value });
  };

  onChangeEmail = (e) => {
    this.setState({ email: e.target.value });
  };
  onChangePassword = (e) => {
    this.setState({ password: e.target.value });
  };

  handleLogin = (e) => {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true,
    });

    AuthService.login(this.state.name, this.state.password).then(
      () => {
        this.props.history.push("/");
        window.location.reload();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        this.setState({
          loading: false,
          message: resMessage,
        });
      }
    );
  };

  componentDidMount() {
    // If you ended up in this page, you deserve to loose your auth cookie
    AuthService.logout();
  }

  render() {
    return (
      <div className="col-md-12">
        <div className="card card-container">
          <form className="form-signin" onSubmit={this.handleLogin}>
            <h1 className="h3 mb-3 font-weight-normal">Oyuncu Girişi</h1>
            <input
              type="text"
              className="form-control"
              placeholder="Oyuncu Adı"
              value={this.state.name}
              onChange={this.onChangeName}
              required
              autoFocus
            />
            <input
              type="password"
              className="form-control"
              placeholder="Şifre"
              value={this.state.password}
              onChange={this.onChangePassword}
              required
            />
            <button
              className="btn btn-lg btn-primary btn-block"
              disabled={this.state.loading}
            >
              {this.state.loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span>Giriş</span>
            </button>
            {this.state.message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {this.state.message}
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    );
  }
}
