import React, { useCallback, useState } from "react";
import { TrioCard, sortHand, ImageForCard } from "./Card";
import GameService from "../services/game.service";
import { OwnedRoundsStatus } from "./OwnedRoundsStatus";
import { determinePlayableCards } from "../helpers/gameplay/cardplay";
import "./PlayerAreas.css";
import capitalize from "../helpers/capitalize";
import VideoClient from "./VideoClient";
import { PlaysInfo, getScoreCard, getTotalScore } from "./PlaysInfo";

const onGiveRound = (playerId) => {
  GameService.giveRoundTo(playerId)
    .then((res) => {
      // OK
    })
    .catch((e) => {
      if (e.response.data && e.response.data.error) {
        console.error(e.response.data.error);
      } else {
        console.error(`An error occured giving the round.`);
      }
    });
};

const PlayerArea = (props) => {
  const { player, isPlayerAdmin, game, showHand, brief, reverseInfoBoxOrder } = props;

  if (!player) {
    return <></>;
  }

  const scoreCard = getScoreCard(game, player);
  const isEndOfRound = game && game.subState === "endofround";
  const didPlayerStartPlay = game && game.playerList[(game.gameStartTurn + 3) % 4].id === player.id;
  const isCouplesGame = game && game.gameType === "esliking";
  const playerIndex = game && game.playerList && player && game.playerList.findIndex((p) => p.id === player.id);
  const playersHand = isCouplesGame && playerIndex >= 0 && sortHand(game.playerHands[playerIndex]);
  const isPlayersTurn = playerIndex === game.playerTurn;

  // Calculate player score
  const totalScore = getTotalScore(scoreCard, isCouplesGame);

  return (
    <div className={`${props.playerStyle} ${reverseInfoBoxOrder ? "align-items-start ml-2" : "align-items-end mr-2"}`}>
      <div className="infoBox align-items-center" style={{ flexDirection: reverseInfoBoxOrder ? "row-reverse" : "" }}>
        {!brief && (
          <div className="playerInfoArea">
            <div className={`nameBadge ${didPlayerStartPlay && "playStarter"}`}>{capitalize(player.name)}</div>
            <div className="d-flex flex-row align-items-center">
              <PlaysInfo scoreCard={scoreCard} couplesGame={isCouplesGame} imageHeight="0.75em" />
            </div>
            <div className={`scoreArea ${totalScore < 0 ? "text-danger" : "text-success"}`}>{totalScore}</div>
          </div>
        )}

        {isCouplesGame && (
          <div className="d-flex justify-content-center w-50" style={{ height: "4.6em" }}>
            {showHand &&
              playersHand.map((c, index) => {
                return (
                  <ImageForCard
                    card={showHand ? c : ""} // Show card back or front based on show my hand state
                    key={`card-${index}`}
                    className="h-100"
                    style={{ marginLeft: index > 0 ? "-2em" : 0 }}
                  />
                );
              })}
          </div>
        )}
        <div
          className="d-flex flex-column justify-content-start border rounded mt-1 ownedHandsArea"
          style={{ width: isCouplesGame ? "50%" : "100%" }}
        >
          <div className="d-flex text-white">Alınan Eller</div>
          <div className="d-flex flex-column justify-content-center" style={{ height: "4em" }}>
            <OwnedRoundsStatus game={game} player={player} />
          </div>
        </div>
        {isPlayerAdmin && isEndOfRound && (
          <button
            className="btn btn-sm btn-primary ml-2"
            type="button"
            onClick={() => onGiveRound(player.id)}
            style={{ position: "absolute" }}
          >
            Give
          </button>
        )}
      </div>
      <div className={`d-flex w-100 ${reverseInfoBoxOrder ? "flex-row" : "flex-row-reverse"}`}>
        <VideoClient isPlayersTurn={isPlayersTurn} videoElementId={player.email} />
        {isCouplesGame && (
          <div className={`nameBadge px-2 align-self-end ${didPlayerStartPlay && "playStarter"}`}>
            {capitalize(player.name)}
          </div>
        )}
      </div>
    </div>
  );
};

const ClickableCard = (props) => {
  const { card, onCardClicked, selected, disabled, displaceOffset } = props;

  const styleWhenSelected = selected && { boxShadow: "0 0.5em 1em 0 red", marginTop: displaceOffset };
  const styleWhenDisabled = disabled && { filter: "brightness(75%)" };
  return (
    <div className="h-100 mr-1" style={{ ...styleWhenSelected, ...styleWhenDisabled, zIndex: 1 }}>
      {/* Replace onClick for TrioCard to the value below to make card disabling active */}
      {/*onClick={!disabled ? onCardClicked : null}*/}
      <TrioCard card={card} onClick={disabled ? null : onCardClicked} style={props.style} />
    </div>
  );
};

const HandDisplay = (props) => {
  const { hand, cardsPlayability, onCardPlayed } = props;
  const [selectedCard, setSelectedCard] = useState(null);

  const onCardClicked = useCallback(
    (card) => {
      if (selectedCard === card) {
        // If the same card is selected, play it
        if (onCardPlayed) onCardPlayed(card);
      } else {
        // Select the card
        setSelectedCard(card);
      }
    },
    [onCardPlayed, selectedCard]
  );

  return (
    <div className="d-flex flex-row justify-content-center h-100" style={{ userSelect: "none" }}>
      {hand.map((c, index) => {
        return (
          <ClickableCard
            card={c}
            key={`card-${index}`}
            onCardClicked={() => onCardClicked(c)}
            selected={selectedCard === c}
            disabled={cardsPlayability && !cardsPlayability[index]}
            displaceOffset="-1em"
            style={{ marginLeft: index > 0 ? "-2em" : 0 }}
          />
        );
      })}
    </div>
  );
};

const MyOwnArea = (props) => {
  const { myself, game, isPlayerAdmin } = props;

  if (!myself) {
    return <></>;
  }

  const mySortedHand = sortHand(props.myHand);
  const scoreCard = getScoreCard(game, myself);
  const isEndOfRound = game && game.subState === "endofround";
  const isCouplesGame = game && game.gameType === "esliking";
  const didIStartPlay = game && game.playerList[(game.gameStartTurn + 3) % 4].id === myself.id;
  const myPlayerIndex = game && game.playerList && myself && game.playerList.findIndex((p) => p.id === myself.id);
  const isMyTurn = myPlayerIndex !== undefined && myPlayerIndex === game.playerTurn && game.subState !== "endofround";

  const cardsPlayability =
    isMyTurn &&
    mySortedHand &&
    determinePlayableCards(mySortedHand, game.currentPlay, game.currentRound, game.playedRounds);
  // Calculate player score
  const totalScore = getTotalScore(scoreCard, isCouplesGame);

  return (
    <div className="d-flex flex-column w-100 h-100">
      <div className="d-flex flex-row justify-content-center align-items-center w-100" style={{ height: "7em" }}>
        <HandDisplay hand={mySortedHand} cardsPlayability={cardsPlayability} onCardPlayed={props.onCardPlayed} />
      </div>
      <div className="d-flex flex-row flex-fill align-items-center my-1 w-100">
        <div className="d-flex flex-row align-items-center justify-content-end w-25"></div>
        <div className="d-flex flex-column align-items-center w-50">
          <div className="d-flex justify-content-center">
            <div
              className="d-flex flex-row justify-content-center align-items-center p-1 m-1"
              style={{ borderRadius: "1em", width: "15em", border: isMyTurn ? "2px solid orange" : "1px solid white" }}
            >
              <div className={`h2 m-0 text-white text-capitalize ${didIStartPlay && "font-weight-bold text-info"}`}>
                {myself.name}
              </div>
              {isPlayerAdmin && isEndOfRound && (
                <button className="btn btn-sm btn-primary ml-2" type="button" onClick={() => onGiveRound(myself.id)}>
                  Give
                </button>
              )}
              <div className="h2 m-0 ml-2">
                {totalScore < 0 ? (
                  <div className="text-danger">{totalScore}</div>
                ) : (
                  <div className="text-success">{totalScore}</div>
                )}
              </div>
            </div>
          </div>
          <div className="">
            <PlaysInfo scoreCard={scoreCard} couplesGame={isCouplesGame} imageHeight="1em" />
          </div>
        </div>
        <div className="d-flex flex-row justify-content-start border rounded w-25 h-100">
          <div className="d-flex text-white">Aldığım Eller</div>
          <div className="d-flex align-self-center mx-4" style={{ height: "4em" }}>
            <OwnedRoundsStatus game={game} player={myself} />
          </div>
        </div>
      </div>
    </div>
  );
};

export { PlayerArea, MyOwnArea };
