import React from "react";
import triangleFilledIcon from "../assets/imgs/triangleFilled.png";
import triangleOutlineIcon from "../assets/imgs/triangleOutline.png";
import circleFilledIcon from "../assets/imgs/circleFilled.png";
import circleOutlineIcon from "../assets/imgs/circleOutline.png";

const PlaysInfo = (props) => {
  const { couplesGame, imageHeight, scoreCard } = props;
  let noOfCeza = 0;
  let noOfKoz = 0;
  if (scoreCard) {
    scoreCard.forEach((sc) => {
      noOfCeza += sc.plays.filter((p) => p === "c").length;
      noOfKoz += sc.plays.filter((p) => p === "k").length;
    });
  }

  var statusImages = [];
  const totalCezas = couplesGame ? 6 : 3;
  const totalKozs = couplesGame ? 4 : 2;

  for (let i = 0; i < totalCezas; i++) {
    if (i < noOfCeza) {
      // prettier-ignore
      statusImages.push(<img src={triangleFilledIcon} style={{ height: imageHeight || "16px" }} alt="ceza" key={`ceza${i}`} />)
    } else {
      // prettier-ignore
      statusImages.push(<img src={triangleOutlineIcon} style={{ height: imageHeight || "16px" }} alt="ceza" key={`ceza${i}`} />)
    }
  }
  for (let i = 0; i < totalKozs; i++) {
    if (i < noOfKoz) {
      // prettier-ignore
      statusImages.push(<img src={circleFilledIcon} style={{ height: imageHeight || "16px" }} alt="ceza" key={`koz${i}`} />)
    } else {
      // prettier-ignore
      statusImages.push(<img src={circleOutlineIcon} style={{ height: imageHeight || "16px" }} alt="ceza" key={`koz${i}`} />)
    }
  }

  return <>{statusImages}</>;
};

const getScoreCard = (game, player) => {
  if (!game || !game.scoreCards) return undefined;

  if (game.gameType === "esliking") {
    // Also return partners cards
    const partnerId = game.playerList[(game.playerList.findIndex((p) => p.id === player.id) + 2) % 4].id;
    return game.scoreCards.filter((s) => s.playerId === player.id || s.playerId === partnerId);
  }
  return game.scoreCards.filter((s) => s.playerId === player.id);
};

const getTotalScore = (scoreCard, isCouplesGame) => {
  var totalScore = 0;
  if (scoreCard) {
    scoreCard.forEach((sc) =>
      sc.playResults.forEach((r) => {
        totalScore += r.playScore;
      })
    );
    if (isCouplesGame) {
      // Don't count the score twice
      totalScore = totalScore / 2;
    }
  }
  return totalScore;
};

export { PlaysInfo, getScoreCard, getTotalScore };
