import React from "react";
import { sortHand } from "../Card";
import capitalize from "../../helpers/capitalize";
import { HandDisplay } from "./CardDisplay";
import { TrioPlaySelector } from "./TrioPlaySelector";

const TrioSelectPlayDisplay = (props) => {
  const { currentPlayer, game } = props;

  const playerIndex = game.playerList.findIndex((p) => p.id === currentPlayer.id);
  const isViewingOnly = playerIndex < 0;
  const isPlayerPlayPicker = !isViewingOnly && game.playPicker === playerIndex;
  const playersHand = !isViewingOnly && sortHand(game.playerHands[playerIndex]);

  return (
    <div className="d-flex flex-column w-100 h-100">
      <div className="d-flex flex-row justify-content-center align-items-center border" style={{ height: "30%" }}>
        <div className="h3 text-white">Top Area</div>
      </div>
      <div className="d-flex flex-row justify-content-center align-items-center border" style={{ height: "30%" }}>
        <div className="h-100 w-25 border border-success">Left Player Area</div>
        <div className="d-flex h-100 w-50 border border-success justify-content-center align-items-center">
          {isPlayerPlayPicker ? (
            <TrioPlaySelector game={game} playerIndex={playerIndex} />
          ) : (
            <div className="h3 text-white">{capitalize(game.playerList[game.playPicker].name)} oyun Seçiyor...</div>
          )}
        </div>
        <div className="h-100 w-25 border border-success">Right Player Area</div>
      </div>
      <div
        className="d-flex flex-column justify-content-center align-items-center border border-danger"
        style={{ height: "40%" }}
      >
        <div className="text-white mb-2" style={{fontSize: "2em"}}>{capitalize(currentPlayer.name)}</div>
        <HandDisplay hand={playersHand} />
      </div>
    </div>
  );
};
export { TrioSelectPlayDisplay };
