import React, { Component } from "react";
import AuthService from "../services/auth.service";

export default class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPlayer: null,
    };
  }

  componentDidMount() {
    const currentPlayer = AuthService.getCurrentPlayer();
    if (!currentPlayer) {
      this.props.history.push("/login");
    }
    this.setState({ currentPlayer: currentPlayer });
  }

  onLogout = () => {
    AuthService.logout();
    this.props.history.push("/login");
  };

  render() {
    const { currentPlayer } = this.state;
    if (!currentPlayer) {
      return <></>;
    }

    return (
      <div className="container">
        <header className="jumbotron">
          <h3>
            <strong>{currentPlayer.name}</strong> Profile
          </h3>
          <p>
            <strong>Token:</strong> {currentPlayer.accessToken.substring(0, 20)} ...{" "}
            {currentPlayer.accessToken.substr(currentPlayer.accessToken.length - 20)}
          </p>
          <p>
            <strong>Id:</strong> {currentPlayer.id}
          </p>
          <p>
            <strong>Email:</strong> {currentPlayer.email}
          </p>
          <strong>Authorities:</strong>
          <ul>{currentPlayer.roles && currentPlayer.roles.map((role, index) => <li key={index}>{role}</li>)}</ul>
          <button className="btn btn-sm btn-primary w-25" onClick={this.onLogout}>
            Çıkış
          </button>
        </header>
      </div>
    );
  }
}
