import React from "react";
import "./Card.css";

// Same as in <top level>/models/card.js
const Suits = ["S", "H", "C", "D"];
const Ranks = ["2", "3", "4", "5", "6", "7", "8", "9", "0", "J", "Q", "K", "A"];

// Read all card images
var CardImagesList = {};
Suits.forEach((s) => {
  Ranks.forEach((r) => {
    const cardKey = `${r}${s}`;
    CardImagesList[cardKey] = require("../assets/cards/" + cardKey + ".svg").default;
  });
});
const CardBack = {
  red: require("../assets/cards/back-red.svg").default,
  black: require("../assets/cards/back-black.svg").default,
};

const TrioCard = (props) => {
  const { card, onClick } = props;
  var selectedCard = card in CardImagesList ? CardImagesList[card] : CardBack["red"];
  if (props.flipped) {
    selectedCard = CardBack["red"];
  }

  return (
    <img
      src={selectedCard}
      alt={`Card ${card}`}
      draggable={false}
      style={{ maxHeight: "100%", width: "auto", ...props.style }}
      onClick={onClick}
    />
  );
};

const ImageForCard = (props) => {
  const { card, onClick } = props;
  var selectedCard = card in CardImagesList ? CardImagesList[card] : CardBack["red"];
  if (props.flipped) {
    selectedCard = CardBack["red"];
  }

  return (
    <img
      src={selectedCard}
      alt={`Card ${card}`}
      draggable={false}
      style={{ height: "100%", maxWidth: "100%", ...props.style }}
      onClick={onClick}
    />
  );
};

function hasTouch() {
  return "ontouchstart" in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
}

const Card = (props) => {
  // if card not given, show back of it
  const cardKey = props.card;

  return (
    <div
      className={`playing-card ${hasTouch() ? "" : "hoverable-card"}`}
      onClick={props.onClick}
      style={props.style}
      aria-haspopup="true"
    >
      <ImageForCard card={cardKey} />
    </div>
  );
};

const sortHand = (hand) => {
  if (!hand) {
    return hand;
  }

  return hand.sort((a, b) => {
    const rankIndexA = Ranks.indexOf(a[0]);
    const suitIndexA = Suits.indexOf(a[1]);
    const rankIndexB = Ranks.indexOf(b[0]);
    const suitIndexB = Suits.indexOf(b[1]);
    if (suitIndexA < suitIndexB || (suitIndexA === suitIndexB && rankIndexA > rankIndexB)) {
      return -1;
    }
    return 1;
  });
};

export { Card, sortHand, ImageForCard, TrioCard };
