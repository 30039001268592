import React, { useState, useEffect } from "react";

import GameService from "../services/game.service";
import PlayerService from "../services/player.service";
import { ErrorMessageDisplay, InfoMessageDisplay } from "../helpers/MessageDisplay";
import { ScoreCardEditor } from "./ScoreCardEditor";
import capitalize from "../helpers/capitalize";
import "./AdminControls.css";

const PlayConfigurationArea = (props) => {
  const { game, setErrorMessage, setInfoMessage } = props;
  const [selectedPlay, setSelectedPlay] = useState(Object.keys(game.allPlayTypes)[0]);

  // Shows if the game has started
  const isGameStarted = game.playerList && game.playerList.length > 0;
  // Shows if the play has started
  const isPlayStarted = game.roundNo != null && game.roundNo >= 0;

  const onStartPlay = () => {
    GameService.startPlay({ type: selectedPlay })
      .then((response) => {
        setInfoMessage(`Play started as ${selectedPlay}`);
      })
      .catch((e) => {
        if (e.response.data && e.response.data.error) {
          setErrorMessage(e.response.data.error);
        } else {
          setErrorMessage(`An error occured starting the play.`);
        }
      });
  };

  const onGoToNextPlay = () => {
    GameService.goToNextPlay()
      .then(() => {
        // setInfoMessage(`Play started as ${selectedPlay}`);
      })
      .catch((e) => {
        if (e.response.data && e.response.data.error) {
          setErrorMessage(e.response.data.error);
        } else {
          setErrorMessage(`An error occured going to the play.`);
        }
      });
  };

  function loadFromSavedGame(saveFileName) {
    GameService.loadGameFromFile(`${saveFileName}`)
      .then(() => {
        setInfoMessage(`Game loaded from ${saveFileName}`);
      })
      .catch((e) => {
        if (e.response.data && e.response.data.error) {
          setErrorMessage(e.response.data.error);
        } else {
          setErrorMessage(`An error occured restarting the play.`);
        }
      });
  }

  const onRedealCurrentPlay = () => {
    if (!game || !game.gameId) {
      // do not restart in case this field is missing
      return;
    }
    loadFromSavedGame(game.gameId);
  };

  const onPutBackLastCard = () => {
    GameService.putLastCardBack()
      .then(() => {
        setInfoMessage(`Last card was put back.`);
      })
      .catch((e) => {
        if (e.response.data && e.response.data.error) {
          setErrorMessage(e.response.data.error);
        } else {
          setErrorMessage(`An error occured putting the card back.`);
        }
      });
  };

  const onUndoGiveRound = () => {
    loadFromSavedGame("beforeroundgive");
  };

  const onRestartLastPlay = () => {
    loadFromSavedGame("beforestartplay");
  };

  return (
    <div className="d-flex flex-column text-light w-100">
      <h5>Play Configuration</h5>
      <div className="form-group row mb-0">
        <label className="col-sm-4 col-form-label-sm">Plays</label>
        <div className="col-sm-4 pr-0">
          <select
            className="form-control form-control-sm"
            onChange={(e) => setSelectedPlay(e.target.value)}
            disabled={!isGameStarted || isPlayStarted}
            value={selectedPlay}
          >
            {game.allPlayTypes &&
              Object.keys(game.allPlayTypes)
                .sort()
                .map((k, index) => (
                  <option key={`play-${index}`} value={k}>
                    {game.allPlayTypes[k]}
                  </option>
                ))}
          </select>
        </div>
        <div className="col-sm-4 pl-0">
          <button
            onClick={onStartPlay}
            className="btn btn-sm btn-primary btn-block"
            disabled={!isGameStarted || isPlayStarted}
          >
            Start Play
          </button>
        </div>
      </div>
      <div className="form-group row mb-2">
        <label className="col-sm-4 col-form-label-sm"></label>
        <div className="col-sm-8">
          <button
            onClick={onGoToNextPlay}
            className="btn btn-sm btn-primary btn-block"
            disabled={!isGameStarted || !isPlayStarted}
          >
            Go To Next Play
          </button>
        </div>
      </div>
      <div className="form-group row mb-3">
        <label className="col-sm-4 col-form-label-sm"></label>
        <div className="col-sm-8">
          <button
            onClick={onRedealCurrentPlay}
            className="btn btn-sm btn-secondary btn-block"
            disabled={!isGameStarted || !isPlayStarted}
          >
            Redeal Current Play
          </button>
        </div>
      </div>
      <div className="form-group row mb-3">
        <label className="col-sm-4 col-form-label-sm"></label>
        <div className="col-sm-8">
          <button
            onClick={onRestartLastPlay}
            className="btn btn-sm btn-info btn-block"
            disabled={!isGameStarted || !isPlayStarted}
          >
            Restart Last Play
          </button>
        </div>
      </div>
      <div className="form-group row mb-3">
        <label className="col-sm-4 col-form-label-sm"></label>
        <div className="col-sm-8">
          <button
            onClick={onUndoGiveRound}
            className="btn btn-sm btn-primary btn-block"
            disabled={!isGameStarted || !isPlayStarted}
          >
            Undo Give Round
          </button>
        </div>
      </div>
      <div className="form-group row mb-0">
        <label className="col-sm-4 col-form-label-sm"></label>
        <div className="col-sm-8">
          <button
            onClick={onPutBackLastCard}
            className="btn btn-sm btn-primary btn-block"
            disabled={!isGameStarted || !isPlayStarted}
          >
            Put Back Last Card
          </button>
        </div>
      </div>
    </div>
  );
};

const AdminControls = (props) => {
  const { game } = props;
  const [errorMessage, setErrorMessage] = useState(null);
  const [infoMessage, setInfoMessage] = useState(null);
  const [allPlayers, setAllPlayers] = useState([]);
  const [chosenPlayer, setChosenPlayer] = useState(null);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [fileNameToSave, setFileNameToSave] = useState("");
  const [gameType, setGameType] = useState("king");
  const [showGame, setShowGame] = useState(false);

  // Shows if the game has started
  const isGameStarted = game && game.playerList && game.playerList.length > 0;

  const fetchData = async () => {
    try {
      const playersResponse = await PlayerService.getRegisteredPlayers();
      if (playersResponse.data) {
        setAllPlayers(playersResponse.data);
        setSelectedPlayers([]);
        if (playersResponse.data.length > 0) {
          setChosenPlayer(playersResponse.data[0]);
        }
      }
    } catch (e) {
      if (e.response.data && e.response.data.error) {
        setErrorMessage(e.response.data.error);
      } else {
        setErrorMessage(`An error occured.`);
      }
    }
  };

  useEffect(() => {
    fetchData();
      }, []);

  const onStartGame = () => {
    GameService.startGame({ players: selectedPlayers.map((p) => p.id) }, gameType)
      .then(() => {
        setInfoMessage(`Game started`);
      })
      .catch((e) => {
        if (e.response.data && e.response.data.error) {
          setErrorMessage(e.response.data.error);
        } else {
          setErrorMessage(`An error occured starting the game.`);
        }
      });
  };

  const onPlayerSelectionDropdownChanged = (e) => {
    setChosenPlayer(allPlayers[e.target.value]);
  };

  const onGameTypeChanged = (e) => {
    setGameType(e.target.value);
  };

  const onAddPlayer = () => {
    if (!chosenPlayer) {
      return;
    }

    // Add the chosen player to the selected players list
    const newSelectedPlayers = selectedPlayers.concat(chosenPlayer);
    var newAllPlayers = allPlayers;
    const idToRemove = newAllPlayers.findIndex((p) => p.id === chosenPlayer.id);
    newAllPlayers.splice(idToRemove, 1);

    if (newAllPlayers.length > 0) {
      setChosenPlayer(newAllPlayers[0]);
    }
    setSelectedPlayers(newSelectedPlayers);
    setAllPlayers(newAllPlayers);
  };

  const onResetSelectedPlayers = () => {
    var newAllPlayers = allPlayers.concat(selectedPlayers);
    if (newAllPlayers.length > 0) {
      setChosenPlayer(newAllPlayers[0]);
    }
    setSelectedPlayers([]);
    setAllPlayers(newAllPlayers);
  };

  const saveGame = (fileName) => {
    if (!fileName) {
      return;
    }
    GameService.saveGameToFile(fileName)
      .then(() => {
        setInfoMessage(`Game is saved to ${fileName}`);
      })
      .catch((e) => {
        if (e.response.data && e.response.data.error) {
          setErrorMessage(e.response.data.error);
        } else {
          setErrorMessage(`An error occured saving the game.`);
        }
      });
  };

  const loadGame = (fileName = fileNameToSave) => {
    if (!fileName) {
      return;
    }
    GameService.loadGameFromFile(fileName)
      .then(() => {
        setInfoMessage(`Game loaded from ${fileName}`);
      })
      .catch((e) => {
        if (e.response.data && e.response.data.error) {
          setErrorMessage(e.response.data.error);
        } else {
          setErrorMessage(`An error occured loading the game.`);
        }
      });
  };

  return (
    <div className="d-flex flex-column w-100">
      <div className="d-flex">
        {errorMessage && (
          <ErrorMessageDisplay
            message={errorMessage}
            onClear={() => {
              setErrorMessage("");
            }}
          />
        )}
        {infoMessage && (
          <InfoMessageDisplay
            message={infoMessage}
            onClear={() => {
              setInfoMessage("");
            }}
          />
        )}
      </div>
      <div className="d-flex flex-row flex-wrap">
        <div className="d-flex col-md-6 flex-column text-light border border-primary rounded p-2 mb-1">
          <h5>Game Creation</h5>
          <div className="d-flex flex-column w-100">
            <div className="form-group row">
              <label className="col-sm-4 col-form-label-sm">Registered Players</label>
              <div className="col-sm-6 pr-0">
                <select
                  className="form-control form-control-sm"
                  onChange={onPlayerSelectionDropdownChanged}
                  value={
                    allPlayers && chosenPlayer ? allPlayers.findIndex((p) => p.id === chosenPlayer.id) : { undefined }
                  }
                >
                  {allPlayers &&
                    allPlayers.map((p, index) => (
                      <option key={`player-${index}`} value={index}>
                        {capitalize(p.name)}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-sm-2 pl-0">
                <button
                  type="button"
                  className="btn btn-sm btn-primary btn-block"
                  onClick={onAddPlayer}
                  disabled={selectedPlayers.length >= 4}
                >
                  Add
                </button>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label-sm">Game Players ({selectedPlayers.length})</label>
              <div className="col-sm-6 pr-0">
                <span className="h5">
                  [{selectedPlayers && selectedPlayers.map((p) => capitalize(p.name)).toString()}]
                </span>
              </div>
              <div className="col-sm-2 pl-0">
                <button
                  className="btn btn-sm btn-primary btn-block"
                  onClick={onResetSelectedPlayers}
                  disabled={selectedPlayers && selectedPlayers.length === 0}
                >
                  <span>Reset</span>
                </button>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label-sm">Game Type</label>
              <div className="col-sm-6 pr-0">
                <select className="form-control form-control-sm" onChange={onGameTypeChanged}>
                  <option value="king">King</option>
                  <option value="trioking">Trio King</option>
                  <option value="esliking">Esli King</option>
                </select>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label-sm"></label>
            <div className="col-sm-8">
              <button
                className="btn btn-sm btn-block btn-primary"
                onClick={onStartGame}
                disabled={selectedPlayers && selectedPlayers.length !== 4 && gameType !== "trioking"}
              >
                <span>{isGameStarted ? "Restart Game" : "Start New Game"}</span>
              </button>
            </div>
          </div>
          <div className="d-flex-fluid flex-row">
            <div className="form-group row">
              <label className="col-sm-4 col-form-label-sm">Save/Load Current Game</label>
              <div className="col-sm-4 pr-0">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Filename"
                  onChange={(e) => setFileNameToSave(e.target.value)}
                ></input>
              </div>
              <div className="col-sm-2 px-0 ">
                <button
                  type="button"
                  className="btn btn-sm btn-primary btn-block"
                  onClick={() => saveGame(fileNameToSave)}
                  disabled={!fileNameToSave}
                >
                  Save
                </button>
              </div>
              <div className="col-sm-2 pl-0">
                <button
                  type="button"
                  className="btn btn-sm btn-primary btn-block"
                  onClick={() => loadGame(fileNameToSave)}
                  disabled={!fileNameToSave}
                >
                  Load
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex col-md-6 border border-success rounded p-2 mb-1">
          {game.allPlayTypes && (
            <PlayConfigurationArea game={game} setErrorMessage={setErrorMessage} setInfoMessage={setInfoMessage} />
          )}
        </div>
      </div>
      <div className="d-flex">
        {game.allPlayTypes && (
          <div className="d-flex flex-column text-light border border-info rounded p-2 mb-1 w-100">
            <h5>Score Cards</h5>
            <ScoreCardEditor
              game={game}
              setErrorMessage={setErrorMessage}
              setInfoMessage={setInfoMessage}
              isPlayerAdmin={true}
            />
          </div>
        )}
      </div>
      <div className="d-flex flex-column align-items-start">
        <button className="btn btn-sm btn-primary mb-1" onClick={() => setShowGame((oldVal) => !oldVal)}>
          {showGame ? "Hide " : "Show "}Game
        </button>
        {showGame && (
          <div className="d-flex logArea w-100">
            <pre>{JSON.stringify(game, null, 2)}</pre>
          </div>
        )}
      </div>
    </div>
  );
};

export { AdminControls };
