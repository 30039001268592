import React from "react";
import capitalize from "../../helpers/capitalize";
import { LobbyDisplay } from "./LobbyDisplay";
import { TrioPlayArea } from "./TrioPlayArea";
import { TrioSelectPlayDisplay } from "./TrioSelectPlayDisplay";
import { TrioKingGameStates } from "../../shared/constants";

const StateInformation = (props) => {
  const { gameState } = props;
  let stateInformation = "";

  switch (gameState) {
    case TrioKingGameStates.PLAYERSINLOBBY:
      stateInformation = "Oynamak için oturun ve `Hazır`a basın";
      break;

    case TrioKingGameStates.WAITFORPLAYTYPE:
      stateInformation = "El seçimi bekleniyor";
      break;

    default:
      break;
  }

  return <div className="m-0 p-0 text-white small text-center">{stateInformation}</div>;
};

const TrioKingTable = (props) => {
  const { game, currentPlayer } = props;
  // This variable is true when game state is "players in the lobby"
  const inTheLobby = game.state === TrioKingGameStates.PLAYERSINLOBBY;
  const isSelectingPlay = game.state === TrioKingGameStates.WAITFORPLAYTYPE;
  const isPlayOn = game.state === TrioKingGameStates.PLAYINGROUND;

  return (
    <div className="d-flex w-100 h-100 flex-row border border-warning">
      {/* Left Side with Game This currentPlayer info */}
      <div className="d-flex col-sm-2 p-0 flex-column border border-info">
        {/* Game Information Area */}
        <div className="mb-1" style={{ height: "40%", borderRadius: "2em", border: "3px solid brown" }}>
          <div className="text-white text-center" style={{ fontSize: "1.2em" }}>
            ÜÇLÜ KİNG
          </div>
          <div className="text-info text-center">
            <b>TODO:</b> General game info here
          </div>
        </div>
        {/* Player Information Area */}
        <div className="border border-primary justify-content-center" style={{ height: "5%" }}>
          <StateInformation gameState={game.state} />
        </div>
        <div className="d-flex flex-column py-2 border border-primary" style={{ height: "55%" }}>
          <div className="text-white h4 text-center">{currentPlayer.name && capitalize(currentPlayer.name)}</div>
          <div className="text-success h5 text-center">Score: 0</div>
        </div>
      </div>
      {/* Right Side => Gameplay area and hands */}
      <div className="col-sm p-0">
        {inTheLobby && <LobbyDisplay playerList={game.playerList} currentPlayer={currentPlayer} />}
        {isSelectingPlay && <TrioSelectPlayDisplay game={game} currentPlayer={currentPlayer} />}
        {isPlayOn && <TrioPlayArea game={game} currentPlayer={currentPlayer} />}
      </div>
    </div>
  );
};

export { TrioKingTable };
