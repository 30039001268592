import { logError } from "../helpers/logger";

const ChatClient = require("../helpers/ChatClient").default;
class VideoChatController {
  constructor(options) {
    this.options = { ...options };

    if (!navigator?.mediaDevices) {
      console.log("no video!");
      return;
    }

    // Create my own video stream
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        this.chatClient = new ChatClient({
          signalServerUrl: "/video",
          clientName: options.player.email,
          token: options.player.accessToken,
          localStream: stream,
          setVideoElement: this.setStreamToVideoElement,
          stopVideoElement: this.stopVideoElement,
        });

        this.options.setOwnVideoStream && this.options.setOwnVideoStream(stream);
      })
      .catch((e) => {
        // Log and ignore error
        logError(`Stream for my own video can't be captured: ${e}`);
      });
  }

  setStreamToVideoElement(elementId, stream) {
    const videoElement = document.getElementById(elementId);

    if (videoElement && "srcObject" in videoElement) {
      videoElement.srcObject = stream;
    }
  }

  stopVideoElement(elementId) {
    const videoElement = document.getElementById(elementId);

    if (videoElement && "srcObject" in videoElement && videoElement.srcObject) {
      const tracks = videoElement.srcObject.getTracks();
      tracks.forEach(function (track) {
        track.stop();
      });

      videoElement.srcObject = null;
    }
  }
}

export default VideoChatController;
